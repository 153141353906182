import React, {useState} from 'react'
import {Link as RouterLink, withRouter} from "react-router-dom";
/*** REDUX  ****/
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
/*** REDUX ACTIONS ***/
import {logOutUser} from "../../../actions/user";
/***MATERIAL UD**/
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from "@material-ui/core/Icon";
import Link from '@material-ui/core/Link';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//STYLE
import './drawer.css'
//OWN COMPONENTS
import SideBarMenuItem from './SideBarMenuItem'
import LogoSVG from '../../../images/bettair_logo_white.png'


const StyledDivider = withStyles({
    root: {
        background: '#333333',
    }
})(Divider);

const StyledIcon = withStyles({
    root: {
        color: '#ffffff',
    }
})(Icon);

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 0,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        backgroundColor: '#212121',
        color: 'white',
        width: drawerWidth,
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        backgroundColor: '#212121',
        color: 'white',
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));


const MiniDrawer = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [user] = useSelector(state => [state.user], shallowEqual);

    const [menuProfile, setMenuProfile] = useState(null);

    const handleDrawerOpen = () => {
        props.openHandle(true);
    };

    const handleDrawerClose = () => {
        props.openHandle(false);
    };

    // function handleSelectOrganization(organization) {
    //     props.userActions.updateOrg(organization);
    //     props.history.push("/stations");
    // }

    // function handleOrganizations() {
    //     let orgs = [];
    //     // props.user.organizations.forEach((item, key) => {
    //     //     if (key < 5) {
    //     //         orgs.push(<MenuItem key={key}
    //     //                             disabled={item.id === props.user.currentOrganization.id}
    //     //                             onClick={handleSelectOrganization(key)}>{item.name}</MenuItem>);
    //     //     }
    //     //     if (key === 5) {
    //     //         orgs.push(<Link to={'/account'}><MenuItem value='more' icon='more_horiz' caption='more'
    //     //                                                   key={key}/></Link>);
    //     //     }
    //     //
    //     // });
    //     return orgs
    // }

    const handleCloseProfile = () => {
        setMenuProfile(null);
    };

    const handleClickMenu = (event) => {
        setMenuProfile(event.currentTarget);
    };

    const handleLogOut = () => {
        sessionStorage.removeItem('x-auth-token');
        sessionStorage.removeItem('refresh_token');

        if (localStorage.getItem("refresh_token")) {
            localStorage.removeItem("refresh_token");
        }

        logOutUser()(dispatch);
        props.history.push("/login");
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.openState,
                    [classes.drawerClose]: !props.openState,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.openState,
                        [classes.drawerClose]: !props.openState,
                    }),
                }}
                open={props.openState}
            >
                <div className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: props.openState,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>

                </div>
                <div>
                    {user.currentOrganization &&
                    <SideBarMenuItem icon="group" text='Organization'
                                     to="/organization"/>}

                    {user.currentOrganization &&
                    <SideBarMenuItem icon="devices" text="Stations" to="/stations"/>}

                    <StyledDivider/>
                </div>

                <div className={clsx("naviLogo", {
                    [classes.hide]: !props.openState,
                })}>
                    <Link component={RouterLink} to={"/"}>
                        <img alt={'Bettair'} src={LogoSVG} className={"drawerLogo"}/>
                    </Link>
                </div>

                <div className={"navigation_bottom"}>
                    <IconButton
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        onClick={handleClickMenu}>
                        <StyledIcon>account_circle</StyledIcon>
                    </IconButton>
                    <div>
                        <Menu
                            id="profile-menu"
                            anchorEl={menuProfile}
                            keepMounted
                            open={Boolean(menuProfile)}
                            onClose={handleCloseProfile}
                        >
                            <MenuItem disabled>{user.userName}</MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleClickMenu}>View Account</MenuItem>
                            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                        </Menu>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};


export default withRouter(MiniDrawer);