import React, {useEffect, useState} from 'react'
import {Route, Switch, withRouter} from "react-router-dom";
/** OWN COMPONENTS **/
import StationList from './StationList/StationList'
import StationCharts from './StationCharts/StationCharts'
import StationNoise from "./StationCharts/StationNoise";
/*** REDUX  ****/
import {shallowEqual, useSelector} from 'react-redux'
/** REACT TOOLBOX ***/
import NotFound from "../../Route/error404";
import {PropsRoute} from "../../Route";


const ManageStations = () => {

    const [organization, stations] = useSelector(states => [states.user.currentOrganization, states.stations.stations], shallowEqual);

    const [state, setState] = useState({
        page: 0,
        stations: []
    });

    useEffect(() => {
        const array1 = organization.stations;
        const array2 = stations;
        const orgaStations = [];

        if (array1.length === array2.length) {
            for (let i = 0; i < array1.length; i++) {
                const currentStation = array1[i].station;
                orgaStations.push({
                    station: array1[i].station,
                    alias: array1[i].alias,
                    lastData: array2.find(e => e.id === currentStation).lastData?.value,
                    lastActivity: array2.find(e => e.id === currentStation).lastActivity.value
                });
            }
        }

        if (organization.stations && state.stations.length === 0) {
            setState({
                ...state,
                stations: orgaStations,
            });
        }
    }, [organization, stations, state]);

    return (
        <Switch>
            <PropsRoute exact path="/dashboard" component={StationList}
                        organization={organization.name}
                        stations={state.stations}
                        count={state.stations.length}
                        page={state.page}
            />
            <PropsRoute exact path="/stations" component={StationList}
                        organization={organization.name}
                        stations={state.stations}
                        count={state.stations.length}
                        page={state.page}
            />
            <Route exact path="/stations/chart/:stations/alias/:alias/from/:from/to/:to"
                   component={StationCharts}/>
            <Route exact path="/stations/chart/:stations/from/:from/to/:to" component={StationCharts}/>
            <Route exact path="/stations/chart/:stations/from/:from" component={StationCharts}/>
            <Route exact path="/stations/chart/:stations/alias/:alias/from/:from"
                   component={StationCharts}/>
            <Route exact path="/stations/chart/:stations/alias/:alias" component={StationCharts}/>
            <Route exact path="/stations/chart/:stations" component={StationCharts}/>
            <Route exact path="/stations/noise/:stations/alias/:alias/from/:from/to/:to"
                   component={StationNoise}/>
            <Route exact path="/stations/noise/:stations/from/:from/to/:to" component={StationNoise}/>
            <Route exact path="/stations/noise/:stations/from/:from" component={StationNoise}/>
            <Route exact path="/stations/noise/:stations/alias/:alias/from/:from"
                   component={StationNoise}/>
            <Route exact path="/stations/noise/:stations/alias/:alias" component={StationNoise}/>
            <Route exact path="/stations/noise/:stations" component={StationNoise}/>
            <Route path='*' component={NotFound}/>
        </Switch>
    )

};

export default withRouter(ManageStations);
