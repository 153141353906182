import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from "react-router-dom";
import {bindActionCreators} from 'redux'

// import {PulseLoader} from 'halogen'

import List from 'react-toolbox/lib/list/List'
import ListItem from 'react-toolbox/lib/list/ListItem'
import IconButton from 'react-toolbox/lib/button/IconButton'
import ListSubHeader from 'react-toolbox/lib/list/ListSubHeader'
import ListDivider from 'react-toolbox/lib/list/ListDivider'

import './OrganizationList.css'


class OrganizationList extends Component {
    constructor() {
        super();
        this.state = {
            page: 0
        }

    }


    handleDelete(id) {
        this.props.handleDeleteOrg(id);
    }

    handleDetail(id) {
        this.props.handleEditOrg(id);
    }

    handleRenderOrganizations() {

        return this.props.organizations.map((entity, key) => {
            return (
                <ListItem
                    key={key}
                    caption={entity.name}
                    rightActions={[
                        <Link to={"/admin/organizations/detail/" + entity.id} key={key}><IconButton
                            key={key}
                            icon='info'
                            primary
                        /></Link>,
                        <IconButton
                            key={key}
                            icon="delete"
                            primary
                            onClick={this.handleDelete.bind(this, entity.id)}
                        />
                    ]}
                />
            )
        })

    }

    handleOrgPage(direction) {
        /*if (direction === "next" && this.props.sensors.count > (this.state.page + 1) * 20) {
            this.state.page++;
            this.props.sensorActions.getDeviceList(localStorage.getItem('x-auth-token'), this.state.page);
        }
        else if (direction === "previous" && this.state.page > 0) {
            this.state.page--;
            this.props.sensorActions.getDeviceList(localStorage.getItem('x-auth-token'), this.state.page);
        }*/
    }

    render() {
        return (
            <div className=" col-xs-12 nodeContent">
                <div className="nodeListContainer">
                    <div className="listHeader">
                        <ListSubHeader className="listSubheader" caption="Organizations"/>
                        <Link to={'/admin/organizations/detail/new'} ><IconButton primary
                                                 className="listHeaderButton"
                                                 icon="add"/></Link>
                    </div>
                    <ListDivider/>
                    <List selectable ripple className={'nodeList'}>
                        {this.props.organizations.length > 0 ? this.handleRenderOrganizations() : null/*<PulseLoader className="LoadTable"
                                                                                                               color="#0A2A59"
                                                                                                               size={"20px"}
                                                                                                               margin={"4px"}/>*/}
                        <ListDivider/>
                        <ListItem key={'Paginator'}
                                  rightActions={[
                                      <IconButton
                                          key={'Paginator'}
                                          icon='keyboard_arrow_left'
                                          primary
                                          onClick={this.handleOrgPage.bind(this, "previous")}
                                      />,
                                      <IconButton
                                          key={'Paginator'}
                                          icon="keyboard_arrow_right"
                                          primary
                                          onClick={this.handleOrgPage.bind(this, "next")}
                                      />
                                  ]}
                        />
                    </List>
                </div>
            </div>
        )
    }
}

const OrganizationsListStateToProps = (state) => {
    return {
        organizations: state.organizations.organizationsList,
    }
};

export default withRouter(connect(OrganizationsListStateToProps, null)(OrganizationList))
