const pad = (number) => {
    if (number < 10) {
        return '0' + number;
    }
    return number;
};

export const toTimeString = (date) => {
    if (typeof (date) != 'undefined') {
        return (date.getUTCFullYear() +
            '-' + pad(date.getUTCMonth() + 1) +
            '-' + pad(date.getUTCDate()) +
            ' - ' + pad(date.getUTCHours()) +
            ':' + pad(date.getUTCMinutes()) +
            ':' + pad(date.getUTCSeconds()) +
            ' ')
    } else {
        return null;
    }
};

export const toShortDateString = (date) => {
    if (typeof (date) != 'undefined') {
        return (
            pad(date.getUTCDate()) +
            '/' + pad(date.getUTCMonth() + 1) +
            '/' + date.getUTCFullYear())
    } else {
        return null;
    }
};

export const checkTime = (time) => {
    let last = new Date(time);
    let diff = new Date() - last;
    let classState = 'timeKo';

    if (diff < 1200000) {
        classState = 'timeOk';
    } else if (diff < 86400000) {
        classState = 'timeWarn';
    }

    return classState;
};