import {
    createUserOrganization as createNewUserOrganization,
    getRoles as getPlatformRoles,
    updateUser as updatePlatformUser
} from '../requests/organizations'

import {getUserDetails} from '../requests/user'

import {listOrganizations, listUsers} from './organizations'
import {getUserInfo} from "./user";

export const CHANGE_FIELD = 'CHANGE_FIELD';
export const CLEAR_USER_FIELDS = 'CLEAR_USER_FIELDS';
export const DISPLAY_POPUP = 'DISPLAY_POPUP';
export const SET_ROLES = 'SET_ROLES';
export const CLOSE_POP_UP = 'CLOSE_POP_UP';
export const MANAGE_USER_INFO = 'MANAGE_USER_INFO';

export const changeField = (field, value) => {
    return {
        type: CHANGE_FIELD,
        field,
        value
    }
};

export const clearFields = () => {
    return {
        type: CLEAR_USER_FIELDS
    }
};


export const createUserOrganization = (user, organization, callback) => {
    return (dispatch) => {
        createNewUserOrganization(user, organization, sessionStorage.getItem('x-auth-token'), (response, err) => {
            if (err) {
                dispatch(displayPopUp({
                    title: 'User could not be created',
                    popUpMSG: response.status === 409 ? 'User already exists' : 'Please check the fields'
                }));
                callback(true);
            } else {

                dispatch(displayPopUp({
                    title: 'User created',
                    popUpMSG: `${user.name} has been successfully created`
                }));
                dispatch(clearFields());
                dispatch(listOrganizations());
                callback(false);
            }
        });
    };
};

export const updateUser = (user, id, self, callback) => {
    return (dispatch) => {
        updatePlatformUser(user, id, sessionStorage.getItem('x-auth-token'), (response, err) => {
            if (err) {
                dispatch(displayPopUp({
                    title: 'User could not be updated',
                    popUpMSG: response.status === 409 ? 'User already exists' : 'Please check the fields'
                }));
            } else {
                dispatch(displayPopUp({
                    title: 'Updated user',
                    popUpMSG: `${user.name} has been successfully updated`
                }));
                if(self)
                    dispatch(listOrganizations());
                else
                dispatch(listUsers());
                dispatch(getUserInfo());
                callback(false);
            }
        });
    }
};


export const setRoles = () => {
    return (dispatch) => {
        getPlatformRoles(localStorage.getItem('x-auth-token'), (roles, err) => {
            if (err) {
                /*if (roles.status === 401)
                    // return dispatch(setLoggedOutUser());TODO
                    null;
                else*/
                    return dispatch(displayPopUp({
                        title: 'Failed to request roles',
                        popUpMSG: `Please reload the page`

                    }));
            } else
                dispatch({
                    type: SET_ROLES,
                    roles
                });
        });
    };
};

/**
 *
 * @param pOpUpInfo {{
 *              title: string,
 *              popUpMSG: string
 *              }}
 * @returns {{type: string, pOpUpInfo: {
 *              title: string,
 *              popUpMSG: string
 *              }}}
 */
export const displayPopUp = (popUpInfo) => {
    return {
        type: DISPLAY_POPUP,
        popUpInfo
    }
};


export const handlePopUp = () => {
    return {
        type: CLOSE_POP_UP,
    }
};


export const getInfoUser = (user) => {
    return (dispatch) => {
        getUserDetails(user, localStorage.getItem('x-auth-token'), (response, error) => {
            /*if (error && response.status === 401) {
                // dispatch(setLoggedOutUser());TODO
                null;
            } else {*/
                dispatch({
                    type: MANAGE_USER_INFO,
                    response
                });
                // dispatch(changeDashboardContent({content: 'admin_organization', view: 'user_form_edit'}));
            // }
        });
    }

};
