import React, {useState} from 'react'
import * as _ from 'lodash';
// REDUX ACTIONS
import {Link} from "react-router-dom";
/**MATERIAL UI**/
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import {checkTime, toTimeString} from '../../../../assets/bettair/generalFunctions'
import Title from '../../../../assets/bettair/Title'


const createData = (stationId, alias, lastData, lastActivity) => {
    const classNameData = checkTime(lastData);
    const classNameActivity = checkTime(lastActivity);
    return {stationId, alias, lastData, lastActivity, classNameData, classNameActivity};
};

const desc = (a, b, orderBy) => {
    if (orderBy === 'lastActivity' || orderBy === 'lastData') {
        if (new Date(b[orderBy]) < new Date(a[orderBy])) {
            return -1;
        }
        if (new Date(b[orderBy]) > new Date(a[orderBy])) {
            return 1;
        }
        return 0;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
};

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const headRows = [
    {id: 'stationId', numeric: false, disablePadding: true, fixedSize: true, sorting: true, label: 'STATION ID'},
    {id: 'alias', numeric: false, disablePadding: false, fixedSize: false, sorting: true, label: 'ALIAS'},
    {
        id: 'lastActivity',
        numeric: false,
        disablePadding: false,
        fixedSize: false,
        sorting: true,
        label: 'LAST CONNECTION'
    },
    {id: 'lastData', numeric: false, disablePadding: false, fixedSize: false, sorting: true, label: 'LAST DATA'},
    {id: 'actions', numeric: true, disablePadding: false, fixedSize: false, sorting: false, label: 'ACTIONS'},
];

const EnhancedTableHeadStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 0,
        width: '150px',
        fontSize: '1em'
    },
    thead: {
        fontSize: '1em'
    }
}));

const EnhancedTableHead = (props) => {

    const classes = EnhancedTableHeadStyles();

    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'Select all desserts'}}
                        color="primary"
                    />
                </TableCell>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        className={row.fixedSize ? classes.root : classes.thead}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        {row.sorting ?
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                            : row.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.primary,
        width: '150px',
        textAlign: 'right',
        paddingRight: '10px'
    },
    title: {
        flex: '0 0 auto',
    },
    iconButton: {
        padding: '5px'
    }
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const {numSelected, selected} = props;

    let stationList = '';
    let aliasList = '';

    selected.forEach(item => {
        stationList += item.station + ',';
        aliasList += item.alias + ',';
    });

    stationList = stationList.slice(0, -1);
    aliasList = aliasList.slice(0, -1);

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h5" id="tableTitle">
                    </Typography>
                )}
            </div>
            <div className={classes.spacer}/>
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <div>
                        {/*<Tooltip title="Show all in map">*/}
                        {/*    <Link to={`/stations/map/${stationList}`}>*/}
                        {/*        <IconButton className={classes.iconButton} color="primary" aria-label="map">*/}
                        {/*            <Icon>location_on</Icon>*/}
                        {/*        </IconButton>*/}
                        {/*    </Link>*/}
                        {/*</Tooltip>*/}
                        <Tooltip title="Plot all">
                            <Link to={`/stations/chart/${stationList}/alias/${aliasList}`}>
                                <IconButton className={classes.iconButton} color="primary" aria-label="map">
                                    <Icon>insert_chart</Icon>
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Plot all">
                            <Link to={`/stations/noise/${stationList}/alias/${aliasList}`}>
                                <IconButton className={classes.iconButton} color="primary" aria-label="map">
                                    <Icon>volume_up</Icon>
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </div>
                ) : (
                    <Tooltip title="None Selected">
                        <div/>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    iconButton: {
        padding: '5px'
    }
}));

const StationList = props => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('stationId');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);


    const rows = props.stations.map(function (entity) {
        return createData(
            entity.station,
            entity.alias,
            entity.lastData,
            entity.lastActivity
        )
    });

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = _.filter(_.map(rows, n => {
                if (n.lastData)
                    return {station: n.stationId, alias: n.alias}
            }));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name, alias) => {
        // const selectedIndex = selected.indexOf(name);
        const selectedIndex = _.findIndex(selected, o => o.station === name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, {station: name, alias: alias});
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    };

    const isSelected = name => _.findIndex(selected, o => o.station === name) !== -1;

    return (
        <div>
            <Paper className={'paper'}>
                <Title>{`${props.organization ? props.organization : ''} Stations`}</Title>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selected={selected}
                />
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.stationId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={row.lastData ? event => handleClick(event, row.stationId, row.alias) : null}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.stationId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.stationId}
                                            </TableCell>
                                            <TableCell align="left">{row.alias}</TableCell>
                                            <TableCell align="left">
                                                <div className={row.classNameActivity}>
                                                    {toTimeString(new Date(row.lastActivity))}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div className={row.classNameData}>
                                                    {row.lastData ? toTimeString(new Date(row.lastData)) : 'No Data'}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                {/*{row.lastData ?*/}
                                                {/*    <Link to={`/stations/map/${row.stationId}`}>*/}
                                                {/*        <IconButton className={classes.iconButton} color="primary"*/}
                                                {/*                    aria-label="map">*/}
                                                {/*            <Icon>location_on</Icon>*/}
                                                {/*        </IconButton>*/}
                                                {/*    </Link> :*/}
                                                {/*    <IconButton className={classes.iconButton} color="primary"*/}
                                                {/*                aria-label="map" disabled={true}>*/}
                                                {/*        <Icon>location_on</Icon>*/}
                                                {/*    </IconButton>*/}
                                                {/*}*/}
                                                {row.lastData ?
                                                <Link to={`/stations/chart/${row.stationId}/alias/${row.alias}`}>
                                                    <IconButton className={classes.iconButton} color="primary"
                                                                aria-label="map">
                                                        <Icon>insert_chart</Icon>
                                                    </IconButton>
                                                </Link> :
                                                <IconButton className={classes.iconButton} color="primary"
                                                            aria-label="map" disabled={true}>
                                                    <Icon>insert_chart</Icon>
                                                </IconButton>}
                                                {row.lastData ?
                                                    <Link to={`/stations/noise/${row.stationId}/alias/${row.alias}`}>
                                                        <IconButton className={classes.iconButton} color="primary"
                                                                    aria-label="map">
                                                            <Icon>volume_up</Icon>
                                                        </IconButton>
                                                    </Link> :
                                                    <IconButton className={classes.iconButton} color="primary"
                                                                aria-label="map" disabled={true}>
                                                        <Icon>volume_up</Icon>
                                                    </IconButton>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[20, 40, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

export default StationList;