import React, {Component} from 'react'

import IconButton from 'react-toolbox/lib/button/IconButton'
/** REDUX **/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as organizationsActions from "../../../../../actions/organizations";

class Organization extends Component {

    handleOrganizationClick(organizationShortName) {
        // this.props.dashboardActions.setOrganization(organizationShortName);
        this.props.organizationsActions.getCurrentOrganizationDetails(organizationShortName);
        this.props.organizationsActions.OrganizationDetails(organizationShortName);
    }

    displayOrganizationInfo() {
        let orgs = this.props.user.organizations.map((org, key) => {
            return (<div className={`organizationData ${key === 0 ? 'activeOrg' : ''}`}
                         onClick={this.handleOrganizationClick.bind(this, org.name)}
                         key={key}>
                <div className={'orgInfo'}>
                    <h4> Short Name: </h4>
                    <h3> {org.name}</h3>
                </div>
                <IconButton
                    primary
                    className={'viewOrganizationButton'}
                    icon={'visibility'}
                    onClick={this.handleOrganizationClick.bind(this, org.name)}
                />
            </div>)
        });
        return orgs.length > 0 ? orgs : <p> Current account is not associated to an organization</p>;
    }

    render() {
        return (
            <div
                className={`${this.props.isXs ? 'col-xs-12' : 'col-sm-6' }  ${this.props.isXs ? 'hidden-sm hidden-md hidden-lg hidden-xl' : ' hidden-xs'} profileColumn right`}>
                <div className={'shapedDiv'}>
                    <h4>Organization</h4>
                </div>
                <div className={'userInfoContent'}>
                    {this.displayOrganizationInfo()}
                </div>
            </div>
        )
    }
}


const stateToProps = (state) => {
    return {
        user: state.user,

    }
};

const dispatchToProps = (dispatch) => {
    return {
        organizationsActions: bindActionCreators(organizationsActions,dispatch)
    }
};

export default connect(stateToProps, dispatchToProps)(Organization)
