import {SEND_DATA, CLEAN_DATA} from "../actions/webSocket";


const initialState = {
    msg: []
};


const WebSocket = (state = initialState, action) => {
    switch (action.type) {
        case SEND_DATA:
            return {
                ...state,
                msg: action.msg
            };

        case CLEAN_DATA:
            return {
                ...state,
                msg: action.msg
            };

        default:
            return state;
    }
};

export default WebSocket