import React, {useState} from 'react'
import {Route, Switch, withRouter} from "react-router-dom";

import SideBar from './SideBar'
import ManageStations from './ManageStations/ManageStations';
import ViewAccount from './ViewAccount/ViewAccount'
import AdminOrganizations from './AdminOrganizations/AdminOrganizations'
/** MATERIAL UI**/
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
/***OWN COMPONENTS **/
import './dashboard.css';
import NotFound from "../Route/error404";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


const DashBoard = () => {
    const classes = useStyles();

    /**Getting Drawer State**/
    let miniDrawerState = sessionStorage.getItem('miniDrawerState');
    if (miniDrawerState === null) {
        sessionStorage.setItem('miniDrawerState', 'open');
        miniDrawerState = true;

    } else {
        miniDrawerState = miniDrawerState === 'open';
    }

    const [openDrawer, setOpenDrawer] = useState(miniDrawerState);

    const openHandle = (newMiniDrawerState) => {
        setOpenDrawer(newMiniDrawerState);
        sessionStorage.setItem('miniDrawerState', newMiniDrawerState ? 'open' : 'close');
    };

    document.getElementById('splash-screen').style.display = 'none';

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <SideBar
                openHandle={openHandle}
                openState={openDrawer}
            />
            <main className={classes.content}>
                <Switch>
                    <Route exact path={'/dashboard'} component={ManageStations}/>
                    <Route exact path={'/account'} component={ViewAccount}/>
                    <Route path={'/stations'} component={ManageStations}/>
                    <Route path={'/organization'} component={AdminOrganizations}/>
                    <Route path='*' component={NotFound}/>
                </Switch>
            </main>
        </div>
    );
};

export default withRouter(DashBoard);