import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link, withRouter} from "react-router-dom";

import List from 'react-toolbox/lib/list/List'
import ListItem from 'react-toolbox/lib/list/ListItem'
import IconButton from 'react-toolbox/lib/button/IconButton'
import ListSubHeader from 'react-toolbox/lib/list/ListSubHeader'
import ListDivider from 'react-toolbox/lib/list/ListDivider'

import './OrganizationUsersList.css'

class OrganizationUsersList extends Component {
    constructor() {
        super();
        this.state = {
            page: 0
        }
    }

    handleUsersPage(direction) {

    }

    handleDelete(user) {
        this.props.handleDeleteUser(user);
    }

    handleEdit(user) {
        this.props.handleEditUser(user);
    }

    handleRenderUsers() {

        return this.props.users.map((entity, key) => {
            return (
                <ListItem
                    key={key}
                    caption={entity.username}
                    rightActions={[
                        <Link to={'/admin/organizations/users/' + entity.id} key={key}><IconButton
                            key={key}
                            icon='info'
                            primary
                        /></Link>,
                        <IconButton
                            key={key}
                            icon="delete"
                            primary
                            onClick={this.handleDelete.bind(this, entity.id)}
                        />
                    ]}

                />
            )
        })

    }

    render() {
        return (
            <div className=" col-xs-12 nodeContent">
                <div className="nodeListContainer">
                    <div className="listHeader">
                        <ListSubHeader className="listSubheader" caption="Users"/>
                        <Link to={'/admin/organizations/users/new'}><IconButton primary
                                                                                onClick={this.props.handleUserFormCreate}
                                                                                className="listHeaderButton"
                                                                                icon="add"/></Link>
                    </div>
                    <ListDivider/>
                    <List selectable ripple className={'userList'}>
                        {this.props.users.length > 0 ? this.handleRenderUsers() : null/*<PulseLoader className="LoadTable"
                                                                                                 color="#0A2A59"
                                                                                                 size={"20px"}
                                                                                                 margin={"4px"}/>*/}
                        <ListDivider/>
                        <ListItem key={'Paginator'}
                                  rightActions={[
                                      <IconButton
                                          key={'Paginator'}
                                          icon='keyboard_arrow_left'
                                          primary
                                          onClick={this.handleUsersPage.bind(this, "previous")}
                                      />,
                                      <IconButton
                                          key={'Paginator'}
                                          icon="keyboard_arrow_right"
                                          primary
                                          onClick={this.handleUsersPage.bind(this, "next")}
                                      />
                                  ]}
                        />
                    </List>
                </div>
            </div>
        )
    }
}

const OrganizationsListStateToProps = (state) => {
    return {
        users: state.organizations.userslist,
    }
};

// const OrganizationsListDispatchToProps = (dispatch) => {
//     return {
//         dashboardActions: bindActionCreators(DashboardActions, dispatch),
//
//     }
// };


export default withRouter(connect(OrganizationsListStateToProps, null)(OrganizationUsersList))
