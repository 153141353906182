import React, {Component} from 'react'
/** REDUX **/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as manageUserActions from '../../../../../actions/manageUsers'
import * as userDetailActions from '../../../../../actions/user'
/** REACT TOOLBOX ***/
import IconButton from 'react-toolbox/lib/button/IconButton'
import Input from 'react-toolbox/lib/input/Input'
import PopUp from '../../../PopUpNotification/PopUp';
import Button from 'react-toolbox/lib/button/Button'
import {ChangePassword} from "./ChangePassword/ChangePassword";
import './Account.css'


class Account extends Component {

    constructor() {
        super();
        this.state = {
            edit: false,
            name: '',
            email: '',
            displayPopUp: false,
            savedChanges: false,
            popUpTitle: '',
            popUpMSG: '',
            changePassword: false
        };
        this.handlePopUp = this.handlePopUp.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }


    componentWillMount() {
        this.setState({...this.state, email: this.props.user.email, name: this.props.user.name})
    }


    handleChangePassword() {
        this.setState({...this.state, changePassword: !this.state.changePassword});
    }

    handleEdit() {
        if (this.state.edit) {
            this.props.manageUserActions.updateUser({
                email: this.state.email,
                username: this.state.name,
                admin: this.props.isAdmin
            }, this.props.user.id, true, (err) => {
                if (!err)
                    this.setState({...this.state, edit: false});
            });
            // }, (updatedUser, err) => {
            //     if (err)
            //         return this.setState({
            //             ...this.state,
            //             edit: this.state.edit,
            //             displayPopUp: true,
            //             popUpTitle: 'Failed',
            //             email: this.props.user.email,
            //             name: this.props.user.name,
            //             popUpMSG: 'Introduced email already exists or check if the fields are correct'
            //         });
            //     else
            //         return this.setState({
            //             ...this.state,
            //             edit: !this.state.edit,
            //             displayPopUp: true,
            //             popUpTitle: 'Success',
            //             popUpMSG: 'User was successfully updated'
            //         });
            // })
        } else {
            this.setState({...this.state, edit: !this.state.edit})
        }


    }

    handleChange(field, value) {
        this.setState({...this.state, [field]: value})
    }


    handlePopUp() {
        this.setState({
            ...this.state,
            displayPopUp: false,
            popUpTitle: '',
            popUpMSG: ''
        });
    }

    handleKey(e) {
        if (e.key === 'Enter' && this.state.edit) {
            e.preventDefault();
            this.handleEdit();
        }
    }

    handleSubmitPassword(password) {
        this.props.userDetailActions.changePassword(password, (response, err) => {
            if (!err)
                this.setState({
                    ...this.state,
                    changePassword: false,
                    displayPopUp: true,
                    popUpTitle: 'Success',
                    popUpMSG: 'Password has been successfully changed'
                });
            else
                this.setState({
                    ...this.state,
                    displayPopUp: true,
                    popUpTitle: 'Password could not be changed',
                    popUpMSG: 'Please check the password fields'
                })
        });
    }

    render() {
        return (
            <div className={'col-sm-6 col-xs-12 profileColumn left'}>
                <PopUp active={this.state.displayPopUp} type="Device"
                       handlePopUp={this.handlePopUp}
                       title={this.state.popUpTitle}
                       popUpMSG={this.state.popUpMSG}/>
                <div className={'shapedDiv'}>
                    <h4>Account</h4>
                </div>
                {this.state.changePassword ?
                    <ChangePassword
                        handleChangePassword={this.handleChangePassword}
                        handleSubmitPassword={this.handleSubmitPassword}/>
                    : <form className={'userInfoContent'} onKeyDown={this.handleKey.bind(this)}>
                        <IconButton primary
                                    className={'editIcon'}
                                    icon={this.state.edit ? 'save' : 'edit'}
                                    onClick={this.handleEdit}
                        />
                        <div className={'userInfoData'}>
                            <Input className="userInput" type="text" label={'Name'} name={'name'}
                                   value={this.state.edit ? this.state.name : this.props.user.userName}
                                   key={'name'}
                                   disabled={!this.state.edit}
                                   onChange={this.handleChange.bind(this, 'name')}/>
                            <Input className="userInput" type="text" label={'Email'} name={'email'}
                                   value={this.state.edit ? this.state.email : this.props.user.email}
                                   key={'email'}
                                   disabled={!this.state.edit}
                                   onChange={this.handleChange.bind(this, 'email')}/>
                        </div>
                        {this.state.edit && <Button label="SAVE CHANGES"
                                                    className={'submitUserEdit'}
                                                    raised
                                                    onClick={this.handleEdit}/>}
                    </form>
                }
                {(!this.state.changePassword && !this.state.edit) &&
                <div className={'row'}>
                    <div className={'col-xs-12 passwordButtonsContainer'}>
                        <Button label={'Change password'} raised
                                icon={'lock'}
                                onClick={this.handleChangePassword}/>
                    </div>
                </div>}
            </div>)
    }
}

const stateToProps = (state) => {
    return {
        user: state.user,
        isAdmin: (state.user.role === 'admin'),

    }
};

const dispatchToProps = (dispatch) => {
    return {
        manageUserActions: bindActionCreators(manageUserActions, dispatch),
        userDetailActions: bindActionCreators(userDetailActions, dispatch),
    }
};

export default connect(stateToProps, dispatchToProps)(Account)
