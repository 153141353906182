import nodeCharts from './stations/stationCharts'
import nodeDetails from './nodes/nodeDetails'
import user from './users/user'
import manageUsers from './users/manageUsers'
import organizations from './organizations/organizations'
import stations from './stations/stations';
import devices from './devices/devices';
import WebSocket from './WebSocket';

import {combineReducers} from 'redux'

export default combineReducers({
    nodeCharts,
    nodeDetails,
    user,
    manageUsers,
    organizations,
    stations,
    devices,
    WebSocket
})
