import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import {Provider} from 'react-redux'
import {applyMiddleware, compose, createStore} from 'redux'
import ReduxThunk from 'redux-thunk'
import logger from 'redux-logger'

import {Dashboard, Error401, error404} from "./components/Route";
import Login from "./components/Login";
import AuthorizedRoute from "./components/Route/AuthorizedRoute";

import reducers from './reducers';

const DEBUG = process.env.REACT_APP_ENVIRONMENT === 'development';

const middleware = [ReduxThunk, DEBUG && logger].filter(Boolean);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

export default () => {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/404" component={error404}/>
                    <Route path="/401" component={Error401}/>
                    <Route path="/login" component={Login}/>
                    <AuthorizedRoute path="/dashboard" component={Dashboard}/>
                    <AuthorizedRoute path="/organization" component={Dashboard}/>
                    <AuthorizedRoute path="/stations" component={Dashboard}/>
                    <AuthorizedRoute path="/account" component={Dashboard}/>
                    <Redirect exact from="/" to="/dashboard"/>
                    <Route path='*' component={error404}/>
                </Switch>
            </Router>
        </Provider>
    );
}