import React from 'react'

import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
// REDUX IMPORTS
import './DateSelector.css'
import Title from "../../../../../assets/bettair/Title";

let dateTo;
let dateFrom;
let stations;
let online;
let group;
let alias;

export const DateSelector = props => {

    dateTo = props.to;
    dateFrom = props.from;
    stations = props.stations;
    online = props.online;
    group = props.group;
    alias = props.alias;

    const handleChange = (item, value) => {
        if (item === 'online')
            value = value.target.checked;
        else if (item === 'group')
            value = value.target.value;
        props.handleChange(item, value);
    };

    const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date(props.from));
    const [selectedDateTo, setSelectedDateTo] = React.useState(new Date(props.to));

    const handleDateChangeFrom = (date) => {
        setSelectedDateFrom(date);

    };

    const handleDateChangeTo = (date) => {
        setSelectedDateTo(date);
    };

    return (
        <div>
            <Title>Data Explorer</Title>
            <div className='buttonUpdate'>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.handleSubmit(selectedDateFrom, selectedDateTo, stations, alias)
                    } }>
                    Get Data
                </Button>
            </div>
            <div className='row datePickerSelector'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="From"
                                format="dd/MM/yyyy"
                                value={selectedDateFrom}
                                onChange={handleDateChangeFrom}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="To"
                                format="dd/MM/yyyy"
                                value={selectedDateTo}
                                onChange={handleDateChangeTo}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disabled={online}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <div className='margin-top-16px'>
                                {/*<InputLabel>Group by</InputLabel>*/}
                                {/*<Select*/}
                                {/*    value={group}*/}
                                {/*    onChange={handleChange.bind(this, 'group')}*/}
                                {/*    disabled={true}*/}
                                {/*>*/}
                                {/*    <MenuItem value={1}>10 Min</MenuItem>*/}
                                {/*    <MenuItem value={3}>30 Min</MenuItem>*/}
                                {/*    <MenuItem value={6}>1 Hour</MenuItem>*/}
                                {/*</Select>*/}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            Live Mode
                            <Switch
                                checked={online}
                                onChange={handleChange.bind(this, 'online')}
                                value="liveMode"
                                color="primary"
                                inputProps={{'aria-label': 'primary checkbox'}}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>


            </div>

        </div>
    );
};