import React from 'react'
import _ from 'lodash'
import './OrganizationForm.css'
/** OWN COMPONENTS */
import PopUp from './../../PopUpNotification/PopUp';
import UserListForm from './UserListForm';
import StationListForm from './StationListForm';
/** IMPORT REACT TOOLBOX COMPONENTS */
// import Autocomplete from 'react-toolbox/lib/autocomplete/Autocomplete';
// import Chip from 'react-toolbox/lib/chip';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
/*** REDUX  ****/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as OrganizationActions from "../../../../actions/organizations";
import {Redirect, withRouter} from "react-router-dom";
/*** REDUX ACTIONS ***/
import Paper from "@material-ui/core/Paper";
import {DialogContentText} from "@material-ui/core";
import Title from "../../../../assets/bettair/Title";

class OrganizationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePopUp: false,
            popUpTitle: 'success',
            popUpMSG: '',
            redirect: false,
            redirect_url: '',

            organization: props.organization ? {
                id: props.organization.id,
                description: props.organization.description,
                name: props.organization.name,
                stations: props.organization.stations,
                users: props.organization.users,
                admin: props.admin ? true : props.organization.users.find(element => element.user_id === props.user_id).isAdmin
            } : {
                id: "",
                description: "",
                name: "",
                stations: [],
                users: [],
                admin: false
            },
            noUsers: props.organization ? _.differenceBy(props.users, props.organization.users.map(item => {
                return {id: item.user_id}
            }), 'id') : [],
            noStations: props.organization ? _.differenceBy(props.stations, props.organization.stations.map(item => {
                return {id: item.station}
            }), 'id') : [],
            newUser: false,
            addIcon: "person_add",
            Dialog: {
                active: false,
                user: '',
                user_id: '',
                body: null,
                stations: [],
                noStations: [],
                newStation: ''
            },
        };

        this.errorEmptyFields = 'Please, fulfill check if all mandatory fields are fulfilled';
        this.titleEmptyFields = 'Form has empty fields';

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePopUp = this.handlePopUp.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.errorPopUp = this.errorPopUp.bind(this);
        this.successPopUp = this.successPopUp.bind(this);
        this.handleUserDetail = this.handleUserDetail.bind(this);
    }

    componentDidUpdate(prevProps) {
        let newState = this.state;

        if (prevProps.organization !== this.props.organization && this.props.organization) {

            newState.organization = {
                id: this.props.organization.id,
                description: this.props.organization.description,
                name: this.props.organization.name,
                stations: this.props.organization.stations,
                users: this.props.organization.users,
                admin: this.props.admin ? true : this.props.organization.users.find(element => element.user_id === this.props.user_id).isAdmin
            };

            if (prevProps.organization ? prevProps.organization.users !== this.props.organization.users : true || prevProps.users !== this.props.users)
                newState.noUsers = _.differenceBy(this.props.users, this.props.organization.users.map(item => {
                    return {id: item.user_id}
                }), 'id');

            if (prevProps.organization ? prevProps.organization.stations !== this.props.organization.stations : true || prevProps.stations !== this.props.stations)
                newState.noStations = _.differenceBy(this.props.stations, this.props.organization.stations.map(item => {
                    return {id: item.station}
                }), 'id');


            this.setState(newState);
        } else if ((prevProps.users !== this.props.users || prevProps.stations !== this.props.stations) && this.props.organization) {
            newState.noUsers = _.differenceBy(this.props.users, this.props.organization.users.map(item => {
                return {id: item.user_id}
            }), 'id');
            newState.noStations = _.differenceBy(this.props.stations, this.props.organization.stations.map(item => {
                return {id: item.station}
            }), 'id');

            this.setState(newState);
        }
    }

    handleKey(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleSubmit();
        }
    }

    handleRender(title) {
        return (
            <div>
                <Paper className={'paper'}>
                    <Title>{title}</Title>

                    <div>
                        <TextField
                            required
                            id="name"
                            label="Name"
                            value={this.state.organization.name}
                            onChange={this.handleChange.bind(this, 'name')}
                            margin="normal"
                            variant="outlined"
                            className={'textfieldFullWidth'}
                            disabled={!this.state.organization.admin}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="description"
                            label="Description"
                            value={this.state.organization.description}
                            onChange={this.handleChange.bind(this, 'description')}
                            margin="normal"
                            variant="outlined"
                            className={'textfieldFullWidth'}
                            disabled={!this.state.organization.admin}
                        />
                    </div>
                </Paper>

                <Paper className={'paper'}>
                    <UserListForm isAdmin={this.state.organization.admin}
                                  users={this.state.organization.users}
                                  noUsers={this.state.noUsers}
                                  shortName={this.state.organization.id}
                                  orgId={this.state.organization.id}
                                  errorPopUp={this.errorPopUp}
                                  successPopUp={this.successPopUp}
                                  handleUserDetail={this.handleUserDetail}
                    />
                </Paper>

                <Paper className={'paper'}>
                    <StationListForm isAdmin={this.state.organization.admin}
                                     stations={this.state.organization.stations}
                                     shortName={this.state.organization.id}
                                     noStations={this.state.noStations}
                                     errorPopUp={this.errorPopUp}
                                     successPopUp={this.successPopUp}
                    />
                </Paper>
            </div>
        );
    }

    handleDeleteStation(station) {
        this.props.organizationActions.delStationUser(this.state.organization.id, this.state.Dialog.user_id, station, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response);
                this.errorPopUp("User station has not been deleted");
            } else {
                let newState = this.state.Dialog;

                newState.noStations.push(station);
                newState.stations.splice(newState.stations.indexOf(station), 1);

                let body;

                body = (<div>
                    {/*{this.handleUserStations(newState.stations)}*/}
                    {/*<Autocomplete className='inputForm' label="Add Station"*/}
                    {/*              direction={"down"}*/}
                    {/*              value={this.state.Dialog.newStation}*/}
                    {/*              source={this.state.Dialog.noStations}*/}
                    {/*              multiple={false}*/}
                    {/*              disabled={false}*/}
                    {/*              showSelectedWhenNotInSource={true}*/}
                    {/*              onChange={this.handleChange.bind(this, 'addStation')}*/}
                    {/*/>*/}
                </div>);

                newState.body = body;

                this.setState({...this.state, Dialog: newState});
            }
        });
    }

    handleAddStation() {

        this.props.organizationActions.addStationUser(this.state.organization.id, this.state.Dialog.user_id, this.state.Dialog.newStation, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response);
                this.errorPopUp("User station has not been added");
            } else {
                let newState = this.state.Dialog;
                let station = this.state.Dialog.newStation;

                newState.stations.push(station);
                newState.noStations.splice(newState.noStations.indexOf(station), 1);
                newState.newStation = '';

                let body;

                body = (<div>
                    {/*{this.handleUserStations(newState.stations)}*/}
                    {/*{newState.noStations.length > 0 ? <Autocomplete className='inputForm' label="Add Station"*/}
                    {/*                                                direction={"down"}*/}
                    {/*                                                value={this.state.Dialog.newStation}*/}
                    {/*                                                source={this.state.Dialog.noStations}*/}
                    {/*                                                multiple={false}*/}
                    {/*                                                disabled={false}*/}
                    {/*                                                showSelectedWhenNotInSource={true}*/}
                    {/*                                                onChange={this.handleChange.bind(this, 'addStation')}*/}
                    {/*/> : ''}*/}
                </div>);

                newState.body = body;
                newState.active = true;

                this.setState({...this.state, Dialog: newState});
            }
        })
    }

    // handleUserStations(list) {
    //     let stations;
    //
    //     stations = list.map((station, key) => {
    //         return (
    //             <Chip key={key} deletable onDeleteClick={this.handleDeleteStation.bind(this, station)}>
    //                 {station}
    //             </Chip>
    //         );
    //     });
    //
    //     return stations;
    // }

    handleUserDetail(user) {

        console.log('User details');
        console.log(user);

        return;

        if (!user.isAdmin && this.state.organization.admin) {

            let newState = this.state.Dialog;

            this.state.organization.users.forEach(item => {
                if (item.user_id === user.user_id) {
                    newState.stations = item.stations;
                }
            });

            this.state.organization.stations.forEach(item => {
                if (!newState.stations.includes(item.station)) {
                    newState.noStations.push(item.station);
                }
            });

            let body;

            body = (<div>
                {/*{this.handleUserStations(newState.stations)}*/}
                {/*{(this.state.organization.stations.length > newState.stations.length) ?*/}
                {/*    <Autocomplete className='inputForm' label="Add Station"*/}
                {/*                  direction={"down"}*/}
                {/*                  value={this.state.Dialog.newStation}*/}
                {/*                  source={this.state.Dialog.noStations}*/}
                {/*                  multiple={false}*/}
                {/*                  disabled={false}*/}
                {/*                  showSelectedWhenNotInSource={true}*/}
                {/*                  onChange={this.handleChange.bind(this, 'addStation')}*/}
                {/*    /> : ""}*/}
            </div>);

            newState.active = true;
            newState.user = user.name;
            newState.user_id = user.user_id;
            newState.body = body;

            this.setState({...this.state, Dialog: newState});
        }
    }

    handleDialog() {
        let newState = this.state.Dialog;
        newState.active = !this.state.Dialog.active;
        newState.newStation = '';
        newState.noStations = [];
        newState.user = '';
        newState.stations = [];
        this.setState({...this.state, Dialog: newState});
    }

    handleChange(name, event) {
        let value = event.currentTarget.value;

        if (name === 'addStation') {
            let newState = this.state.Dialog;
            newState.newStation = value;
            this.setState({...this.state, Dialog: newState});
            this.handleAddStation();
        } else {
            let newState = this.state;
            newState.organization[name] = value;
            this.setState(newState);
        }
    };

    handleSubmit() {
        let cansubmit = true;

        if (this.state.organization.description === '' || this.state.organization.name === '')
            cansubmit = false;

        if (!cansubmit)
            this.errorPopUp(this.titleEmptyFields, this.errorEmptyFields);
        else {
            let entity = {
                description: this.state.organization.description,
                name: this.state.organization.name,
            };
            if (this.props.isNew)
                this.handlePost(entity);
            else
                this.handleUpdate(entity, this.state.organization.id)
        }
    }

    handlePost(entity) {
        this.props.organizationActions.postOrg(entity, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response);
                this.errorPopUp("Organization has not been registered")
            } else {
                console.log(response.data.organization.id);
                let newstate = this.state;
                newstate.redirect = true;
                newstate.redirect_url = '/admin/organizations';
                this.setState(newstate);
            }
        });
    }

    handleUpdate(entity, organization) {
        this.props.organizationActions.putOrg(organization, entity, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response);
                this.errorPopUp("Organization has not been registered")
            } else {
                this.successPopUp(`Organization has been successfully updated`);
            }
        });
    }

    successPopUp(msg) {
        this.setState({
            ...this.state,
            activePopUp: true,
            popUpTitle: `success`,
            popUpMSG: msg
        });
    }

    errorPopUp(msg) {
        this.setState({
            ...this.state,
            activePopUp: true,
            popUpTitle: 'error',
            popUpMSG: msg
        })
    }

    handlePopUp() {
        this.setState({...this.state, activePopUp: false});
    }

    render() {
        if (this.state.redirect)
            return <Redirect push to={this.state.redirect_url}/>;

        let title = this.props.isNew ? ' Organization Registration ' : ' Organization Details ';

        return (
            <div>
                <form onKeyDown={this.handleKey.bind(this)}>
                    <div>
                        {this.handleRender(title)}
                    </div>
                    {(this.props.admin || this.state.organization.admin) &&
                    <div className={"buttonUpdate"}>
                        <Button onClick={this.handleSubmit}
                                variant="contained"
                                color="primary">
                            {this.props.isNew ? 'Create' : 'Update'}
                        </Button>
                    </div>}
                </form>

                <Dialog open={this.state.Dialog.active}
                        fullWidth={true}
                        maxWidth={'sm'}
                        onClose={this.handleDialog}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {`Stations of ${this.state.Dialog.user}`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.Dialog.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <PopUp
                    active={this.state.activePopUp}
                    handlePopUp={this.handlePopUp}
                    title={this.state.popUpTitle}
                    message={this.state.popUpMSG}
                />
            </div>
        )
    }
}

const OrganizationsListStateToProps = (state, props) => {
    if (props.match.params.id) {
        return {
            admin: true,
            organization: state.organizations.organizationsList.find(element => element.id === props.match.params.id),
            isNew: props.match.params.id === 'new',
            users: state.organizations.userslist,
            stations: state.stations.stations
        };
    } else if (state.user.admin) {
        return {
            admin: true,
            organization: state.user.currentOrganization,
            isNew: false,
            users: state.organizations.userslist,
            stations: state.stations.stations
        };
    } else {
        return {
            admin: state.user.admin,
            user_id: state.user.id,
            organization: state.user.currentOrganization,
            isNew: false,
            users: [],
            stations: []
        }
    }

};

const OrganizationsListDispatchToProps = (dispatch) => {
    return {
        organizationActions: bindActionCreators(OrganizationActions, dispatch),
    }
};


export default withRouter(connect(OrganizationsListStateToProps, OrganizationsListDispatchToProps)(OrganizationForm))
