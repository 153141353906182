import React from 'react'
import './OrganizationForm.css'
/** IMPORT REACT TOOLBOX COMPONENTS */
/*** REDUX  ****/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as OrganizationActions from "../../../../actions/organizations";
/*** REDUX ACTINS ***/
import {Link} from "react-router-dom";

import Icon from "@material-ui/core/Icon";
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Title from "../../../../assets/bettair/Title";


/** OWN COMPONENTS */

export const UserListForm = props => {
    //Sort Users
    const sortedUsers = props.users.sort(function (a, b) {
        if (a.username) {
            return a.username.localeCompare(b.username);
        }
    });

    const [state, setState] = React.useState({
        newUser: false,
        toolTipAddIcon: 'Add User',
        addIcon: 'person_add',
        users: sortedUsers
    });

    const handleAddUser = (event, name) => {
        console.log('handleAddUser');
        if (state.newUser) {
            setState({...state, newUser: false, addIcon: "person_add", toolTipAddIcon: 'Add User'});
        } else {
            setState({...state, newUser: true, addIcon: "close", toolTipAddIcon: 'Cancel'});
        }
    };

    const handleChange = (event, value) => {
        console.log('Change in new user');
        console.log(value.props.value);

        let index = props.noUsers.findIndex(entity => entity.email === value.props.value);
        if (index >= 0) {
            let user = {
                name: props.noUsers[index].id,
                isAdmin: false
            };
            props.organizationActions.postUserOrg(props.shortName, user, (response, err) => {
                if (err) {
                    console.log("ERROR -> " + response);
                    props.errorPopUp('User role has not been changed');
                } else {
                    props.successPopUp('User added to organisation');
                    handleAddUser();
                }
            });
        } else {
            props.errorPopUp('User not found, cannot be added');
            handleAddUser();
        }
    };

    const handleDelete = (event, user) => {
        props.organizationActions.delUserOrg(props.shortName, user, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response);
                props.errorPopUp('User has not been deleted');
            } else {
                props.successPopUp('User deleted');
            }
        });
    }

    const handleUser = (event, id) => {
        let auxUser = state.users;
        let index = auxUser.findIndex(entity => entity.user_id === id);
        if (index >= 0) {
            let user = {
                name: auxUser [index].user_id,
                isAdmin: !auxUser [index].isAdmin
            };

            auxUser[index].isAdmin = !state.users[index].isAdmin;

            props.organizationActions.postUserOrg(props.shortName, user, (response, err) => {
                if (err) {
                    props.errorPopUp('User role for "' + state.users[index].username + '" has not been changed');
                } else {
                    props.successPopUp('User role for "' + state.users[index].username + '" updated successfully');
                    setState({
                        ...state,
                        newUser: state.newUser,
                        addIcon: state.addIcon,
                        users: auxUser
                    });

                }
            });
        }
    }

    const handleUserDetail = (event, user) => {
        props.handleUserDetail(user);
    }

    const handleUsers = (users) => {
        return users.map((user, key) => {
            return (
                <ListItem
                    button
                    key={`user_${key}`}
                    onClick={event => handleUserDetail(event, user)}>
                    <ListItemText
                        primary={user.username}
                    />
                    <ListItemSecondaryAction>
                        {props.isAdmin && !user.isAdmin &&
                        <Tooltip title="Define user stations">
                            <IconButton
                                key={`inf_${key}`}
                                edge="end"
                                aria-label="Edit"
                                onClick={event => handleUserDetail(event, user)}>
                                <Icon>
                                    perm_device_information
                                </Icon>
                            </IconButton>
                        </Tooltip>}

                        <IconButton
                            key={`adm_${key}`}
                            edge="end"
                            aria-label="Edit"
                            disabled={!props.isAdmin || (props.user_id === user.user_id && !props.global_admin)}
                            onClick={event => handleUser(event, user.user_id)}>
                            <Icon>
                                {user.isAdmin ? 'how_to_reg' : 'person'}
                            </Icon>
                        </IconButton>

                        {props.isAdmin &&
                        <IconButton
                            key={`del_${key}`}
                            edge="end"
                            aria-label="Edit"
                            disabled={!props.isAdmin || (props.user_id === user.user_id && !props.global_admin)}
                            onClick={event => handleDelete(event, user)}>
                            <Icon>
                                {props.isAdmin ? "delete" : null}
                            </Icon>
                        </IconButton>}
                    </ListItemSecondaryAction>

                </ListItem>
            );
        });
    };

    return (
        <div>
            {(props.noUsers.length > 0) && props.global_admin &&
            <div className="buttonClose">
                <Tooltip title={state.toolTipAddIcon}>
                    <IconButton color='primary' onClick={handleAddUser.bind()}>
                        <Icon>{state.addIcon}</Icon>
                    </IconButton>
                </Tooltip>
            </div>}

            {!props.global_admin && props.isAdmin &&
            <div className="buttonClose">
                <Tooltip title="Add new user">
                    <Link to={`/organization/user/new/${props.orgId}`}>
                        <IconButton color='primary'>
                            <Icon>{state.addIcon}</Icon>
                        </IconButton>
                    </Link>
                </Tooltip>
            </div>}

            <Title>Organisation Users</Title>
            {state.newUser ?
                <div>
                    <Select
                        value={''}
                        onChange={handleChange.bind('newUser')}
                        inputProps={{
                            name: 'user',
                            id: 'new-user-email',
                        }}
                    >{props.noUsers.map(entity => (
                        <MenuItem value={entity.email}
                                  key={`user_${entity.email}`}>{entity.email}
                        </MenuItem>
                    ))}
                    </Select>
                </div> : ""}
            <List>
                {handleUsers(state.users)}
            </List>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        organizationProp: state.organizations.currentOrganizationDetails,
        user_id: state.user.id,
        global_admin: state.user.admin,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        organizationActions: bindActionCreators(OrganizationActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListForm)
