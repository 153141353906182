import {
    CHANGE_FIELD,
    SET_ROLES,
    DISPLAY_POPUP,
    CLOSE_POP_UP,
    CLEAR_USER_FIELDS,
    MANAGE_USER_INFO
} from '../../actions/manageUsers'

const initialState = {
    user: {
        name: '',
        email: '',
        password: '',
        role: '',
        orgs: []
    },
    roles: [],
    popUp: {
        title: '',
        popUpMSG: '',
        activePopUp: false
    }
};

const manageUsers = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_FIELD:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.field]: action.value
                }
            };

        case SET_ROLES:
            return {
                ...state,
                roles: action.roles
            };

        case CLOSE_POP_UP:
            return {
                ...state,
                popUp: {
                    activePopUp: false,
                    title: '',
                    popUpMSG: ''
                }
            };
        case CLEAR_USER_FIELDS:
            return {
                ...state,
                user:{
                    name: '',
                    email: '',
                    password: '',
                    role: ''
                }
            };
        case DISPLAY_POPUP:
            return {
                ...state,
                popUp: {
                    activePopUp: true,
                    title: action.popUpInfo.title,
                    popUpMSG: action.popUpInfo.popUpMSG
                }
            };
        case MANAGE_USER_INFO:
            return {
                ...state,
                user:{
                    name: action.response.name,
                    email: action.response.email,
                    role: action.response.role,
                    orgs: action.response.organizations
                }
            };
        default:
            return state
    }
};

export default manageUsers
