import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";


const useTitleStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

export default function Title(props) {
    const classes = useTitleStyles();

    return (
        <Typography component="h2" variant="h5" color="primary" className={classes.root}>
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};