import React from 'react'
//REACT TOOLBOX
import Icon from '@material-ui/core/Icon';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from "@material-ui/core";

const StyledIcon = withStyles({
    root: {
        color: '#ffffff',
    }
})(Icon);

const StyledLink = withStyles({
    root: {
        color: '#ffffff',
        textDecoration: 'none',
        '&:hover': {
            color: '#CCCCCC',
            textDecoration: 'none',
        }
    }
})(Link);

const SideBarMenuItem = props => {
    return (
        <StyledLink component={RouterLink} to={props.to}>
            <ListItem
                button
            >
                <ListItemIcon>
                    <StyledIcon>
                        {props.icon}
                    </StyledIcon>
                </ListItemIcon>
                <ListItemText primary={props.text}/>
            </ListItem>
        </StyledLink>
    )
};

export default SideBarMenuItem;