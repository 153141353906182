import {getNoiseDataFromDevice, getProcessedDataFromDevice,} from '../requests/processed'

import async from 'async';
import * as _ from 'lodash';

export const FETCH_DATA_FROM_NODES = 'FETCH_DATA_FROM_NODES';
export const LOADING = 'LOADING';
export const ERROR_REQ = 'ERROR_REQ';
export const NO_DATA = 'NO_DATA';
export const SET_DATE = 'SET_FROM';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const CHANGE_MIN_MAX = 'CHANGE_MIN_MAX';


export const fetchDataFromNode = (nodes, token, from, to, alias, callback = false) => {
    // dispatch(noData(false, nodes));
    // dispatch(removeErrors(true));
    // dispatch(setLoading(true));
    let batchFinal = {
        gases: {axis: {units: '(µg/m³)', variables: []}, axis2: {units: '(mg/m³)', variables: []}},
        particles: {axis: {units: '(µg/m³)', variables: []}},
        ambient: {axis: [], axis2: []},
        noise: {axis: []},
        LABELS: []
    };
    let batch = {
        gases: {
            CO: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'CO',
                units: '(mg/m³)',
                yaxis: 'y2',
            },
            H2: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'H2',
                units: '(µg/m³)'
            },
            NO: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'NO',
                units: '(µg/m³)'
            },
            NO2: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'NO2',
                units: '(µg/m³)'
            },
            O3: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'O3',
                units: '(µg/m³)'
            },
            SO2: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'SO2',
                units: '(µg/m³)'
            },
        },
        particles: {
            PM_1: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'PM1',
                units: '(µg/m³)'
            },
            PM_10: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'PM10',
                units: '(µg/m³)'
            },
            PM_2P5: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'PM2.5',
                units: '(µg/m³)'
            },
        },
        ambient: {
            PRESSURE: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'Pressure (hPa)',
                yaxis: 'y2',
            },
            R_HUMIDITY: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'Relative Humidity (%)'
            },
            TEMPERATURE: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'Temperature (°C)'
            },
        },
        noise: {
            NOISE: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'Noise (dB)'
            }
        },
        LABELS: ['Time']
    };

    // async.eachOf(nodes, (node, ind, cb) => {
    const syncFunt = (node, ind) => {
        getProcessedDataFromDevice(node, token, from, to, (resp, err) => {
            if (err) {
                if (resp.status === 401)
                    // dispatch(setLoggedOutUser());TODO
                    console.log('error 401');
                // else {
                // dispatch(noData(true, nodes));
                // return setTimeout(() => {
                //     dispatch(noData(false, nodes));
                // }, 100)
                // cb();
                // }
            } else {
                // dispatch(setData(nodes, resp.data.data, Object.keys(resp.data.errors).map(node => {
                //     return `${node} : ${resp.data.errors[node]}`;
                // })));
                // dispatch(setLoading(false));
                // batch.push(resp.data);
                let positives = ['CO', 'H2', 'NO', 'NO2', 'O3', 'SO2', 'PM_1', 'PM_10',
                    'PM_2P5', 'PRESSURE', 'R_HUMIDITY', 'TEMPERATURE', 'NOISE'];

                async.eachOf(resp.data, (value, key, cb2) => {
                    if (!positives.includes(key))
                        cb2();
                    // else if (ind === 0) {
                    else {
                        let subkey = _.findKey(batch, function (o) {
                            return _.has(o, key)
                        });

                        let keyName = `${node}_${key}`;

                        batchFinal[subkey][keyName] = Object.create(batch[subkey][key]);
                        batchFinal[subkey][keyName].name = `${alias[ind].alias ? alias[ind].alias : node} - ${batch[subkey][key].name}`;
                        batchFinal[subkey][keyName].x = [];
                        batchFinal[subkey][keyName].y = [];

                        if (_.filter(value, o => o.value).length !== 0) {
                            if (batchFinal[subkey][keyName].units) {
                                batchFinal[subkey][keyName].name += ` ${batchFinal[subkey][keyName].units}`;

                                if (batchFinal[subkey][keyName].yaxis)
                                    batchFinal[subkey].axis2.variables.push(batch[subkey][key].name);
                                else
                                    batchFinal[subkey].axis.variables.push(batch[subkey][key].name);

                            } else if (batchFinal[subkey][keyName].yaxis)
                                batchFinal[subkey].axis2.push(batch[subkey][key].name);
                            else
                                batchFinal[subkey].axis.push(batch[subkey][key].name);

                            async.each(value, (item, cb3) => {
                                let value2 = item.value;
                                if (positives.includes(key) && value2 < 0)
                                    value2 = null;
                                batchFinal[subkey][keyName].x.push(new Date(item.modifiedAt));
                                batchFinal[subkey][keyName].y.push(value2);
                                cb3();
                            }, err => {
                                if (err) console.log(err);
                                cb2();
                            });

                        } else
                            cb2();

                        // } else {
                        //     async.eachOf(value, (item, indArr, cb3) => {
                        //         console.log(key, batch);
                        //         let value = item.value;
                        //         if (positives.includes(key) && value < 0)
                        //             value = null;
                        //         batch[key][indArr].push(value);
                        //
                        //         cb3();
                        //     }, err => {
                        //         if (err) console.log(err);
                        //         cb2();
                        //     });
                        // }
                    }
                }, err => {
                    if (err) console.log(err);
                    batchFinal.LABELS.push(node);
                    // cb();
                    if (ind === nodes.length - 1) {
                        async.eachOf(batchFinal, (item, key, cbf) => {

                            if (batchFinal[key].axis?.variables)
                                batchFinal[key].axis.variables = _.uniq(item.axis.variables);
                            else if (batchFinal[key].axis?.length > 0)
                                batchFinal[key].axis = _.uniq(item.axis);

                            if (batchFinal[key].axis2?.variables)
                                batchFinal[key].axis2.variables = _.uniq(item.axis2.variables);
                            else if (batchFinal[key].axis2?.length > 0)
                                batchFinal[key].axis2 = _.uniq(item.axis2);

                            cbf();
                        }, err => {
                            if (err) console.log(err);

                            callback(batchFinal, false);
                        });
                    } else {
                        syncFunt(nodes[ind + 1], ind + 1);
                    }
                });
            }
        })
    };

    syncFunt(nodes[0], 0);

    // }, err => {
    //     if (err) console.log(err);
    //     callback(batch, false);
    // });
};


export const fetchNoiseDataFromNode = (nodes, token, from, to, alias, callback = false) => {
    // dispatch(noData(false, nodes));
    // dispatch(removeErrors(true));
    // dispatch(setLoading(true));
    let batchFinal = {
        noise: {axis: []},
        LABELS: []
    };
    let batch = {
        noise: {
            NOISE: {
                type: 'scatter',
                mode: 'lines+points',
                name: 'Noise (dB)'
            }
        },
        LABELS: ['Time']
    };

    // async.eachOf(nodes, (node, ind, cb) => {
    const syncFunt = (node, ind) => {
        getNoiseDataFromDevice(node, token, from, to, (resp, err) => {
            if (err) {
                if (resp.status === 401)
                    // dispatch(setLoggedOutUser());TODO
                    console.log('error 401');
                // else {
                // dispatch(noData(true, nodes));
                // return setTimeout(() => {
                //     dispatch(noData(false, nodes));
                // }, 100)
                // cb();
                // }
            } else {
                // dispatch(setData(nodes, resp.data.data, Object.keys(resp.data.errors).map(node => {
                //     return `${node} : ${resp.data.errors[node]}`;
                // })));
                // dispatch(setLoading(false));
                // batch.push(resp.data);
                let positives = ['NOISE'];

                async.eachOf(resp.data, (value, key, cb2) => {
                    if (!positives.includes(key))
                        cb2();
                    // else if (ind === 0) {
                    else {
                        let subkey = _.findKey(batch, function (o) {
                            return _.has(o, key)
                        });

                        let keyName = `${node}_${key}`;

                        batchFinal[subkey][keyName] = Object.create(batch[subkey][key]);
                        batchFinal[subkey][keyName].name = `${alias[ind].alias ? alias[ind].alias : node} - ${batch[subkey][key].name}`;
                        batchFinal[subkey][keyName].x = [];
                        batchFinal[subkey][keyName].y = [];

                        if (_.filter(value, o => o.value).length !== 0) {
                            if (batchFinal[subkey][keyName].units) {
                                batchFinal[subkey][keyName].name += ` ${batchFinal[subkey][keyName].units}`;

                                if (batchFinal[subkey][keyName].yaxis)
                                    batchFinal[subkey].axis2.variables.push(batch[subkey][key].name);
                                else
                                    batchFinal[subkey].axis.variables.push(batch[subkey][key].name);

                            } else if (batchFinal[subkey][keyName].yaxis)
                                batchFinal[subkey].axis2.push(batch[subkey][key].name);
                            else
                                batchFinal[subkey].axis.push(batch[subkey][key].name);

                            async.each(value, (item, cb3) => {
                                let value2 = item.value;
                                if (positives.includes(key) && value2 < 0)
                                    value2 = null;
                                batchFinal[subkey][keyName].x.push(new Date(item.modifiedAt));
                                batchFinal[subkey][keyName].y.push(value2);
                                cb3();
                            }, err => {
                                if (err) console.log(err);
                                cb2();
                            });

                        } else
                            cb2();

                        // } else {
                        //     async.eachOf(value, (item, indArr, cb3) => {
                        //         console.log(key, batch);
                        //         let value = item.value;
                        //         if (positives.includes(key) && value < 0)
                        //             value = null;
                        //         batch[key][indArr].push(value);
                        //
                        //         cb3();
                        //     }, err => {
                        //         if (err) console.log(err);
                        //         cb2();
                        //     });
                        // }
                    }
                }, err => {
                    if (err) console.log(err);
                    batchFinal.LABELS.push(node);
                    // cb();
                    if (ind === nodes.length - 1) {
                        async.eachOf(batchFinal, (item, key, cbf) => {

                            if (batchFinal[key].axis?.variables)
                                batchFinal[key].axis.variables = _.uniq(item.axis.variables);
                            else if (batchFinal[key].axis?.length > 0)
                                batchFinal[key].axis = _.uniq(item.axis);

                            if (batchFinal[key].axis2?.variables)
                                batchFinal[key].axis2.variables = _.uniq(item.axis2.variables);
                            else if (batchFinal[key].axis2?.length > 0)
                                batchFinal[key].axis2 = _.uniq(item.axis2);

                            cbf();
                        }, err => {
                            if (err) console.log(err);

                            callback(batchFinal, false);
                        });
                    } else {
                        syncFunt(nodes[ind + 1], ind + 1);
                    }
                });
            }
        })
    };

    syncFunt(nodes[0], 0);

    // }, err => {
    //     if (err) console.log(err);
    //     callback(batch, false);
    // });
};


const setLoading = (isLoading) => {
    return {
        type: LOADING,
        isLoading: isLoading
    }
};

const setData = (nodeID, data, errors) => {
    return {
        type: FETCH_DATA_FROM_NODES,
        data,
        errors,
        nodeID
    }
};

/**
 *
 * @param min {number}
 * @param max {number}
 * @returns {{type: string, min: *, max: *}}
 */
export const changeMinMaxSelector = (min, max) => {
    return {
        type: CHANGE_MIN_MAX,
        min,
        max
    }
};

const noData = (noData, nodesId) => {
    return {
        type: NO_DATA,
        noData,
        nodesId,
        errors: []
    }
};
export const removeErrors = (key) => {
    return {
        type: REMOVE_ERRORS,
        key
    }
};

export const setDate = (name, value) => {
    return {
        type: SET_DATE,
        name,
        value
    }
};
