import {historical} from '../config/axios'
import {handleError} from "./common";


export const getProcessedDataFromDevice = (node, token, from, to, callback) => {
    historical.get(`/entities/${node}?timerel=between&time=${from}&endtime=${to}&limit=10000&offset=0`, {
        headers: {
            "X-Auth-token": token,
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            console.log(error);
            handleError(error, callback)
        })
};


export const getNoiseDataFromDevice = (node, token, from, to, callback) => {
    historical.get(`/entities/${node}/attrs/noise?timerel=between&time=${from}&endtime=${to}&limit=150000&offset=0`, {
        headers: {
            "X-Auth-token": token,
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            console.log(error);
            handleError(error, callback)
        })
};
