import React, {useState} from 'react'

import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Paper from "@material-ui/core/Paper";

import "./createUserForm.css"

import {Link, Redirect, withRouter} from "react-router-dom";

import {useDispatch} from 'react-redux'
import {createUserOrganization} from '../../../../actions/manageUsers';
import Title from "../../../../assets/bettair/Title";

export const AddUserForm = props => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        user: {
            id: '',
            name: '',
            email: '',
            password: ''
        },
        passwordErr: false,
        emailErr: false,
        create: true,
        redirect: false
    });


    const handleChange = name => value => {
        let newState = {...state};
        newState.user[name] = value.target.value;
        setState(newState);
    };

    const handleSubmit = () => {
        let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let emailErr = re.test(String(state.user.email).toLowerCase());

        let passwordErr = state.user.password.length < 6;

        if (emailErr && !passwordErr) {
            let body = {
                email: state.user.email,
                username: state.user.name,
                password: state.user.password,
                admin: false
            };

            createUserOrganization(body, props.match.params.id, (err) => {
                if (!err)
                    setState({...state, redirect: true});
            })(dispatch);

        } else {
            setState({
                ...state,
                passwordErr,
                emailErr: !emailErr,
            });
        }
    };

    const handleKey = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }

    };

    if (state.redirect) {
        return (
            <Redirect push to={'/organization'}/>
        );
    }

    return (
        <div>
            <div>
                <Paper className={'paper'}>

                    <Title>{'User Creation Form'}</Title>

                    <Link to={'/organization'} className='top-right-button'>
                        <IconButton color="primary" aria-label="close">
                            <Icon>close</Icon>
                        </IconButton>
                    </Link>

                    <form onKeyDown={handleKey.bind(this)}>

                        <TextField
                            error={state.emailErr}
                            id={'email'}
                            key={`key_email`}
                            label={'Email'}
                            value={state.user.email}
                            onChange={handleChange('email') || ''}
                            margin="normal"
                            className="textfieldFullWidth"
                            helperText={state.emailErr ? "Please introduce a valid email." : null}
                        />

                        <TextField
                            id={'name'}
                            key={`key_name`}
                            label={'Name'}
                            value={state.user.name}
                            onChange={handleChange('name') || ''}
                            margin="normal"
                            className="textfieldFullWidth"
                        />

                        <TextField
                            error={state.passwordErr}
                            id={'password'}
                            key={`key_password`}
                            label={'Password'}
                            value={state.user.password}
                            onChange={handleChange('password') || ''}
                            margin="normal"
                            className="textfieldFullWidth"
                            helperText={state.passwordErr ? "Please introduce a valid password. Min length 6 characters." : null}
                            type="password"
                        />

                        {/*<Button className="inputForm" accent raised label="Submit"*/}
                        {/*        onClick={handleSubmit}/>*/}
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Create
                        </Button>
                    </form>
                </Paper>
            </div>
        </div>
    )
};

export default withRouter(AddUserForm)
