// import {
//     getDeviceDetails
// } from '../requests'

export const ADD_GENERAL_INFO = 'ADD_GENERAL_INFO';
export const ADD_GAS_INFO = 'ADD_GAS_INFO';
export const CHANGE_GAS_FIELD = 'CHANGE_GAS_FIELD';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const CHANGE_PM_FIELD = 'CHANGE_PM_FIELD';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';

export const addGeneralInfo = (generalInfo) => {
    return {
        type: ADD_GENERAL_INFO,
        generalInfo
    }
};

export const addGasInfo = (gasInfo, gasNumber) => {
    return {
        type: ADD_GAS_INFO,
        gasInfo,
        gasNumber
    }
};

export const changeGasField = (gasNumber, field, value) => {
    return {
        type: CHANGE_GAS_FIELD,
        field,
        gasNumber,
        value
    }

};
export const changePMField = (field, value) => {
    return {
        type: CHANGE_PM_FIELD,
        field,
        value
    }

};
export const registerNode = () => {

};


export const clearRegister = () => {
    return {
        type: CLEAR_FIELDS
    }
};

// export const displayDeviceInfo = (deviceId) => {
//     return (dispatch) => {
//         getDeviceDetails(deviceId, localStorage.getItem('x-auth-token'), (entity, err) => {
//             if (!err) {
//                 let sortModel = createGasesInfo();
//                 let sortedGasesInfo = entity.gasesInfo.value.map((gasInfo, index) => {
//                     return sortObject(gasInfo, Object.keys(sortModel[index]));
//                 });
//                 dispatch({
//                     type: GET_DEVICE_INFO,
//                     PM: entity.PM.value,
//                     gasesInfo: sortedGasesInfo,
//                     generalInfo: entity.generalInfo.value
//                 });
//                 dispatch(changeDashboardContent({content: 'admin_nodes', view: 'detail'}))
//             }
//             else if (err && entity.status === 401)
//                 dispatch(setLoggedOutUser);
//         });
//     }
// };

// /**
//  *
//  * @param device {string}
//  * @param callback {function({}| null,boolean)}
//  * @returns {function(*)}
//  */
// export const getDeviceInfo = (device, callback) => {
//     return dispatch => {
//         getDeviceDetails(device, localStorage.getItem('x-auth-token'), (entity, err) => {
//             if (err && entity.status === 401){
//                 dispatch(setLoggedOutUser());
//             } else if(err && entity.status !== 401){
//                 callback(null, true);
//             }
//             else {
//                 let sortModel = createGasesInfo();
//                 let sortedGasesInfo = entity.gasesInfo.value.map((gasInfo, index) => {
//                     return sortObject(gasInfo, Object.keys(sortModel[index]));
//                 });
//                 callback(sortedGasesInfo, false);
//             }
//         })
//     };
// };
//


