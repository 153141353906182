import {GET_USER_INFO, UPDATE_ORG, MUST_LOGIN, LOGGED_OUT} from '../../actions/user'

const initialState = {
    id: null,
    userName: null,
    email: null,
    admin: null,
    logged: false,
    mustLogIn: false,
    organizations: [],
    currentOrganization: null
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_INFO:
            return {
                ...state,
                id: action.user.id,
                userName: action.user.username,
                email: action.user.email,
                admin: action.user.admin,
                organizations: action.user.organizations,
                currentOrganization: action.user.currentOrganization,
                logged: true
            };
        case UPDATE_ORG:
            return {
                ...state,
                currentOrganization: state.organizations[action.organization]
            };
        case MUST_LOGIN:
            return {
                ...state,
                mustLogIn: true
            };
        case LOGGED_OUT:
            return initialState;
        default:
            return state
    }
};

export default user
