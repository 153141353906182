import React, {Component} from 'react'

import Input from 'react-toolbox/lib/input/Input'
import Button from 'react-toolbox/lib/button/Button'
import Checkbox from 'react-toolbox/lib/checkbox/Checkbox'


export class ChangePassword extends Component {


    constructor() {
        super();

        this.state = {
            password: '',
            original_password: '',
            displayPassword: false,
            error: false,
            errorTxt: ''
        };
    }

    handleChange(field, value) {
        this.setState({...this.state, [field]: value, error: false, errorTxt: ''})
    }


    handleSubmitPassword() {
        if (this.state.password.length === 0)
            this.setState({...this.state, error: true, errorTxt: 'Please introduce a valid password'});
        else if (this.state.original_password !== this.state.password)
            this.setState({...this.state, error: true, errorTxt: 'Please introduce a same password'});
        if (this.state.password.length < 6)
            this.setState({
                ...this.state,
                error: true,
                errorTxt: ' Introduced password is not valid, length must be 6'
            });
        else
            this.props.handleSubmitPassword({
                password: this.state.password,
            });
    }

    handleKey(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleSubmitPassword();
        }
    }

    render() {

        return (
            <div className={'row'}>
                <div className={'row col-xs-12'} style={{marginTop: '10px'}}>
                    <form className={' userInfoContent'} onKeyDown={this.handleKey.bind(this)}>
                        <Input label={'New Password'}
                               className={'userInput'}
                               type={this.state.displayPassword ? 'text' : 'password'}
                               value={this.state.original_password}
                               onChange={this.handleChange.bind(this, 'original_password')}/>
                        <Input label={'Repeat New Password'}
                               className={'userInput'}
                               type={this.state.displayPassword ? 'text' : 'password'}
                               value={this.state.password}
                               onChange={this.handleChange.bind(this, 'password')}/>
                        {this.state.error &&
                        <span style={{
                            color: 'red',
                            fontWeight: 'bold',
                        }}>{this.state.errorTxt}</span>}
                        <Checkbox checked={this.state.displayPassword}
                                  className={'passwordCheckBox'}
                                  label="See password"
                                  onChange={this.handleChange.bind(this, 'displayPassword')}/>
                    </form>
                </div>
                <div className={'row'}>
                    <div className={'col-xs-12 passwordButtonsContainer'}>
                        <Button label={'Return'} raised
                                icon={'arrow_back'}
                                onClick={this.props.handleChangePassword}/>

                        <Button label={'Save'} raised
                                icon={'save'}
                                onClick={this.handleSubmitPassword.bind(this)}/>
                    </div>
                </div>
            </div>
        )
    }

}
