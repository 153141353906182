import React from 'react';
import {Route, Switch} from 'react-router-dom';

import DashboardComponent from '../DashBoard';
import NotFound from "./error404";
import Unauthorised from './error401';

export const Dashboard = (props) => {
    return (
        <Switch>
            <Route path={`${props.match.path}`} component={DashboardComponent}/>
            <Route path='*' component={NotFound}/>
        </Switch>
    );
};

export const error404 = ({match}) => (
    <Route path={`${match.url}`} component={NotFound}/>
);

export const Error401 = ({match}) => (
    <Route path={`${match.url}`} component={Unauthorised}/>
);

export const PropsRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (<Component {...props} {...rest}/>)}/>
);