import {organizations} from '../config/axios';
import {handleError} from './common'

export const getUserDetails = (token, callback) => {
    if (token !== null) {
        organizations.get('oauth2/token?access_token=' + token)
            .then(response => {
                callback(response.data, false)
            })
            .catch(error => {
                handleError(error, callback, (token_n) => getUserDetails(token_n, callback))
            })
    } else {
        callback(null, true);
    }
};


export const getUserToken = (body, callback) => {
    organizations.post('oauth2/token', body, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, false)
        })
};

/** TODO **/
export const updateUserInfo = (email, user, token, callback) => {
    organizations.put(`user/${email}`, user
        , {
            headers: {
                "Content-Type": "application/json",
                'x-auth-token': token
            }
        })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback, (token_n) => updateUserInfo(email, user, token_n, callback));
        })
};


export const delUser = (user, token, callback) => {
    organizations.delete(`user/${user}`, {
        headers: {
            'x-auth-token': token
        }
    })
        .then(response => {
            callback(response.data, false);
        })
        .catch(err => {
            handleError(err, callback)
        })
};

/**
 *
 * @param userEmail {string}
 * @param password  {{password:string, original_password:string}}
 * @param token {string}
 * @param callback {function({}| {status:number, error:string}, boolean)}
 */
export const changePassword = (userEmail, password, token, callback) => {
    organizations.post(`/user/${userEmail}/password`, password,
        {
            headers: {
                'x-auth-token': token
            }
        })
        .then(response => {
            callback(response.data, false);
        })
        .catch(error => {
            handleError(error, callback);
        })
};
