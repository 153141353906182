import React from "react";

const error404 = (props) => {
    return (
        <h5>
            404 - {props.location.pathname} is not found.
        </h5>
    );
};

export default error404;