import React, {Component} from 'react'
/** OWN COMPONENTS **/
import UserInfo from './UserInfo/UserInfo'
/*** REDUX  ****/
import {connect} from 'react-redux'
import Icon from "@material-ui/core/Icon";


/** REACT TOOLBOX ***/

class ViewAccount extends Component {

    render() {
        return (
            <div className={'row variableHeight minus10PxMargin'}>
                <div className={'row'} style={{'height': '20%'}}>
                    <div className=" col-xs-11">
                        <h3 className="ViewAccountHeader">
                            <Icon
                                value={'account_circle'}
                                style={{
                                    'fontSize': '40px',
                                    margin: '20px 10px 17px 0px',
                                }}/>
                            <b>Manage Account</b></h3>
                    </div>
                </div>
                <UserInfo/>
            </div>
        )
    }

}


const dashboardStateToProps = (state) => {
    return {
        isAdmin: state.user.admin
    }
};

export default connect(dashboardStateToProps, null)(ViewAccount)
