import React from "react";

const Error401 = (props) => {
    return (
        <div>
            <h5>
                401 - Access to {props.location} denied.
            </h5>
        </div>
    );
};

export default Error401;