import React from 'react';

import Plot from 'react-plotly.js';
import * as _ from 'lodash';

import './LineChart.css';

const LineChart = (props) => {
    const makeAxisTitle = axis => {
        try {
            if (axis.units)
                return `${axis.variables.join(', ')} ${axis.units}`;

            return axis.join(', ');
        } catch (e) {
            return undefined;
        }
    };

    const layout = {
        title: `${props.name[0].toUpperCase() + props.name.slice(1)}`,
        autosize: true,
        showlegend: true,
        legend: {
            x: 1.05,
            y: 0.9
        },
        yaxis: {title: makeAxisTitle(props.batch.axis)},
        yaxis2: {
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 1,
            titlefont: {color: 'rgb(50,75,218)'},
            tickfont: {color: 'rgb(50,75,218)'},
            title: makeAxisTitle(props.batch.axis2)
        }
    };

    return (
        <Plot
            data={_.filter(_.map(props.batch, (value) => {
                if (value.x && value.x.length > 0)
                    return value
            }))}
            layout={layout}
            style={{width: '100%'}}
            useResizeHandler={true}
            // config={{responsive: true}}
            config={{displayModeBar: false}}
        />
    );
};

export default LineChart;