import {changePassword as changeUserPassword, delUser, getUserDetails, updateUserInfo} from '../requests/user';
import {getOrganizationDetails} from "../requests";
import {getInfoUser} from "./manageUsers";
import {getAllStationsOrganization} from "./stations";
import {listUsers} from "./organizations";
import async from 'async';

export const GET_USER_INFO = 'GET_USER_INFO';
export const UPDATE_ORG = 'UPDATE_ORG';
export const MUST_LOGIN = 'MUST_LOGIN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const getUserInfo = () => {
    return (dispatch) => {
        getUserDetails(sessionStorage.getItem('x-auth-token'), (user, err) => {
            if (!err) {
                // user.admin = user.roles[0].name === 'Admin';
                // if (user.admin) {
                //     dispatch(getAllDevices());
                //     dispatch(getAllStations(user.admin));
                // }

                dispatch(getAllStationsOrganization());

                async.eachOf(user.organizations, (org, i, cb) => {
                    getOrganizationDetails(sessionStorage.getItem('x-auth-token'), org.id, (response, err) => {
                        if (err) {
                            console.log(response);
                            cb();
                        } else {
                            user.organizations[i] = response;
                            cb();
                        }
                    });
                }, err => {
                    if (err) {
                        console.log(err);
                    }
                    if (user.organizations.length > 0) {
                        user.currentOrganization = user.organizations[0];
                    } else {
                        user.currentOrganization = null;
                    }

                    dispatch({
                        type: GET_USER_INFO,
                        user
                    });
                });
            } else {
                //user.mustLogIn = true;
                dispatch({
                    type: MUST_LOGIN,
                    //user
                });
            }
        });
    }
};

export const updateOrg = (organization) => {
    return dispatch => {
        dispatch({
            type: UPDATE_ORG,
            organization
        })
    }
};

export const updateUser = (email, user, callback) => {
    return dispatch => {
        updateUserInfo(email, user, sessionStorage.getItem('x-auth-token'), (response, err) => {
            if (!err) {
                dispatch({
                    type: GET_USER_INFO,
                    user: response
                });
                callback(response, err)
            } else if (user.status === 401 && err)
                console.log(err); //dispatch(setLoggedOutUser()); TODO
            else callback(response, err)
        });
    }
};


export const deleteUser = (user, callback) => {
    return dispatch => {
        delUser(user, sessionStorage.getItem('x-auth-token'), (response, error) => {
            if (error)
                console.log(response);
            else {
                callback(response, error);
                dispatch(listUsers());
            }
        });
    }
};

export const editUser = (user) => {
    return (dispatch) => {
        dispatch(getInfoUser(user.email));
    }
};

/**
 * @param password {{password:string, original_password:string}}
 * @returns {function(*, *)}
 */
export const changePassword = (password, callback) => {
    return (dispatch, getState) => {

        changeUserPassword(getState().user.id, password, sessionStorage.getItem('x-auth-token'), (resp, error) => {
            if (!error) {
                // getUserToken({
                //     "user": {
                //         "name": getState().user.email,
                //         "password": password.password
                //     }
                // }, (response, err) => {
                //     if (!err) {
                //         localStorage.setItem('x-auth-token', response.headers["x-subject-token"]);
                //         localStorage.setItem('user', response.data.token.user.name);
                //
                //         //dispatch(loginUser(response.data.token, response.data.organizations, response.headers["x-subject-token"]));
                //     }
                // });
                callback(null, false);
            } else {
                if (resp.status === 401)
                    console.log(401); //dispatch(setLoggedOutUser()); TODO
                else
                    callback(resp, true);
            }
        })
    }
};


export const logOutUser = () => {
    return (dispatch) => {
        dispatch({
            type: LOGGED_OUT
        })
    }
};
