import axios from 'axios'

export const orion = axios.create({
    baseURL: 'https://api.bettair.city/context/v2/',
    timeout: 5000
});

export const organizations = axios.create({
    baseURL: 'https://api.bettair.city',
    timeout: 5000
});

export const historical = axios.create({
    baseURL: 'https://api.bettair.city/historical/v2/',
    timeout: 15000
});
