import React, {useEffect, useState} from 'react'
import {CSVLink} from "react-csv";
import './StationCharts.css'
/** OWN COMP **/
import {DateSelector} from './DateSelector/DateSelector'
import {fetchDataFromNode} from '../../../../actions/nodeCharts'
import LineChart from '../../../Chart/LineChart';
import {toShortDateString} from '../../../../assets/bettair/generalFunctions'
import Paper from "@material-ui/core/Paper";
import PopUp from "../../PopUpNotification/PopUp";
import GetAppIcon from '@material-ui/icons/GetApp';
// import async from 'async';
import _ from 'lodash';
import Loader from '../loader';
// REDUX IMPORTS
// import {shallowEqual, useDispatch, useSelector} from 'react-redux'
// import {cleanBatch} from '../../../../actions/stations';

const StationCharts = (props) => {
    // const [batch] = useSelector(state => [state.stations.batch], shallowEqual);

    const [state, setState] = useState({
        stations: null,
        download: false,
        alias: null,
        from: new Date(new Date() - 1000 * 60 * 60 * 24),
        to: new Date(),
        online: false,
        group: 1,
        graphs: {
            gases: {
                CO: {
                    active: false,
                    handle: null
                },
                H2: {
                    active: false,
                    handle: null
                },
                NO: {
                    active: false,
                    handle: null
                },
                NO2: {
                    active: false,
                    handle: null
                },
                O3: {
                    active: false,
                    handle: null
                },
                SO2: {
                    active: false,
                    handle: null
                }
            },
            particles: {
                PM_1: {
                    active: false,
                    handle: null
                },
                PM_10: {
                    active: false,
                    handle: null
                },
                PM_2P5: {
                    active: false,
                    handle: null
                }
            },
            ambient: {
                PRESSURE: {
                    active: false,
                    handle: null
                },
                R_HUMIDITY: {
                    active: false,
                    handle: null
                },
                TEMPERATURE: {
                    active: false,
                    handle: null
                }
            },
            noise: {
                NOISE: {
                    active: false,
                    handle: null
                }
            }
        },
        sync: [],
        loading: true,
        batch: null,
        labels: null,

        //error: false,
        //errorMsg: '',
        //errorTittle: '',

        activePopUp: false,
        popUpTitle: 'success',
        popUpMSG: ''
    });

    useEffect(() => {
        // /stations/chart/STATION_01,STATION_02,STATION_03,/from/2018-08-27T15:32:22Z/to/2018-08-28T15:32:22
        let stations;
        let dateFrom = props.match.params.from ? new Date(props.match.params.from) : state.from;
        dateFrom = new Date(dateFrom - 1000 * ((dateFrom.getHours() * 60 + dateFrom.getMinutes()) * 60 + dateFrom.getSeconds()));
        let dateTo = props.match.params.to ? new Date(props.match.params.to) : state.to;
        let alias = props.match.params.alias ? props.match.params.alias.split(',') : null;

        stations = props.match.params.stations.split(',');
        if (!stations[stations.length - 1]) {
            stations = stations.slice(0, -1);
        }

        if (alias) {
            alias = stations.map((station, key) => {
                return {id: station, alias: alias[key]}
            });
        }

        let newState = state;
        newState.stations = stations;
        newState.alias = alias;

        setState(newState);

        //Get data for station
        handleSubmit(dateFrom, dateTo, stations, alias);
    }, [props.match.params]);


    // useEffect(() => {
    //
    //     // if (!state.loading) {
    //     //
    //     //     // if (state.batch) {
    //     //     //     let oldState = state.graphs;
    //     //     //     _.forEach(state.batch, (serie, key) => {
    //     //     //         if (key !== 'LABELS' && serie.x.length > 0) {
    //     //     //             oldState[key].active = true;
    //     //     //         }
    //     //     //     });
    //     //     //     setState({...state, graphs: oldState});
    //     //     // }
    //     //
    //     // }
    //
    // }, [state.loading]);

    const loaderHandleSubmit = (dateFrom, dateTo, stations, alias) => {
        let newState = state;
        newState.loading = true;

        setState(newState);

        handleSubmit(dateFrom, dateTo, stations, alias);
    };

    const handleSubmit = (dateFrom, dateTo, stations, alias) => {
        let now = new Date();

        if (dateTo > now || dateFrom > now) {
            errorPopUp(`${dateTo > now ? '"To" date ' + toShortDateString(dateTo) : '"From" date ' + toShortDateString(dateFrom)} is in the future`);
        } else if (dateFrom > dateTo) {
            errorPopUp('Incorrect period. "From" date is higher than "dateTo"');
        } else if ((dateTo - dateFrom) > 1000 * 60 * 60 * 24 * 31) {
            errorPopUp('Selected period is too long (1 month max)');
        } else {
            dateFrom = new Date(dateFrom - 1000 * (((dateFrom.getHours() - 1) * 60 + dateFrom.getMinutes()) * 60 + dateFrom.getSeconds()));
            let stationsAux = stations ?? props.match.params.stations.split(',');
            if (!stationsAux[stationsAux.length - 1]) {
                stationsAux = stationsAux.slice(0, -1);
            }

            let aliasAux = alias ?? props.match.params.alias ? props.match.params.alias.split(',') : null;
            if (aliasAux) {
                aliasAux = stationsAux.map((station, key) => {
                    return {id: station, alias: aliasAux[key]}
                });
            }

            window.history.replaceState(null, 'Bettair', `/stations/chart/${stationsAux.toString()}${aliasAux ? `/alias/${aliasAux.map(entity => entity.alias).join()}` : ''}/from/${dateFrom.toISOString()}/to/${dateTo.toISOString()}`);
            //setState({...state, stations, from: dateFrom, to: dateTo, sync: []});

            fetchDataFromNode(stationsAux, sessionStorage.getItem('x-auth-token'),
                dateFrom.toISOString(), dateTo.toISOString(), aliasAux, (response, err) => {
                    if (err) {
                        console.log(response);
                    } else {
                        let label = response['LABELS'];

                        delete response['LABELS'];

                        if (aliasAux) {
                            label = [label[0]].concat(label.slice(1).map(entity => aliasAux.find(item => item.id === entity).alias))
                        }

                        let newState = state;
                        newState.loading = false;
                        newState.batch = response;
                        newState.labels = label;
                        setState(newState);

                        successPopUp('Station data loaded');
                    }
                });
        }
    };

    const handleChange = (field, value) => {
        setState({...state, [field]: value});
    };

    const successPopUp = (msg) => {
        setState({
            ...state,
            activePopUp: true,
            popUpTitle: `success`,
            popUpMSG: msg
        });
    };

    const errorPopUp = (msg) => {
        setState({
            ...state,
            activePopUp: true,
            popUpTitle: 'error',
            popUpMSG: msg
        })
    };

    const handlePopUp = () => {
        setState({...state, activePopUp: false});
    };

    const handleSync = (name, graph) => {
        let sync = state.sync;
        let graphs = state.graphs;

        sync.push(graph);
        graphs[name].handle = graph;

        setState({...state, sync: sync, graphs: graphs});
    };


    const handleDisplay = () => {
        return Object.keys(state.graphs).map(serie => {
            if (state.batch[serie].axis.length > 0 || Object.keys(state.batch[serie].axis).length > 0)
                return <div key={serie}>
                    <LineChart batch={state.batch[serie]} labels={state.labels} name={serie} key={serie}/>
                </div>;

            return null;
        });
    };

    const handleCSV = () => {
        let csvData = null;
        let number = state.stations ? state.stations.length : 0;
        let name = [];
        if (number) {
            if (state.alias)
                state.alias.forEach(item => {
                    name.push(`${item.alias}_${state.from.toISOString()}_${state.to.toISOString()}_ugm3.csv`);
                });
            else
                state.stations.forEach(item => {
                    name.push(`${item}_${state.from.toISOString()}_${state.to.toISOString()}_ugm3.csv`);
                });
            if (state.batch) {
                // let label = ['CO', 'H2', 'NO', 'NO2', 'O3', 'PM_1', 'PM_10', 'PM_2P5', 'PRESSURE', 'R_HUMIDITY', 'SO2', 'TEMPERATURE'];
                let label = ['CO', 'H2', 'NO', 'NO2', 'O3', 'PM_1', 'PM_10', 'PM_2P5', 'PRESSURE', 'R_HUMIDITY', 'SO2', 'TEMPERATURE', 'NOISE'];

                let start = null;
                state.stations.forEach(item => {
                    label.forEach(item2 => {
                        let subkey = _.findKey(state.graphs, function (o) {
                            return _.has(o, item2)
                        });
                        let mainKey = item + '_' + item2;
                        if (state.batch[subkey][mainKey].x.length > 0)
                            start = {subkey, mainKey};
                    });
                });

                if (start) {
                    csvData = [];
                    for (let i = 0; i < number; i++)
                        csvData.push([]);
                    for (let i = 0; i < state.batch[start.subkey][start.mainKey].x.length; i++) {
                        let aux = {};
                        aux['INDEX'] = i + 1;
                        aux['EPOCH'] = Math.trunc(state.batch[start.subkey][start.mainKey].x[i].getTime() / 1000);
                        aux['TIMESTAMP'] = state.batch[start.subkey][start.mainKey].x[i].toISOString();

                        for (let e = 0; e < number; e++) {
                            label.forEach(item => {
                                let subkey = _.findKey(state.graphs, function (o) {
                                    return _.has(o, item)
                                });
                                let mainKey = state.stations[e] + '_' + item;
                                if (state.batch[subkey][mainKey].x.length > 0)
                                    aux[item] = state.batch[subkey][mainKey].y[i];
                            });

                            csvData[e].push(Object.assign({}, aux));
                        }
                    }
                }

            }
        }

        if (csvData) {
            if (state.alias)
                return (
                    <div>
                        <br/>
                        <p style={{marginBottom: '15px'}}>Download Data:</p>
                        {state.alias.map((station, i) => {
                            return (<CSVLink
                                key={station.alias}
                                data={csvData[i]}
                                filename={name[i]}
                                className="csvLink"
                            >
                                <span>{station.alias}</span> <GetAppIcon/>
                            </CSVLink>);
                        })
                        }
                    </div>
                );
            else
                return (
                    <div>
                        <br/>
                        <p style={{marginBottom: '15px'}}>Download Data:</p>
                        {state.stations.map((station, i) => {
                            return (
                                <CSVLink
                                    key={i}
                                    data={csvData[i]}
                                    filename={name[i]}
                                    className="csvLink"
                                >
                                    {station} <GetAppIcon/>
                                </CSVLink>);
                        })}
                    </div>);
        } else
            return null;

    };

    return (
        <div>
            <Paper className={'paper'}>
                <DateSelector
                    from={state.from}
                    to={state.to}
                    stations={state.stations}
                    handleSubmit={loaderHandleSubmit}
                    handleChange={handleChange}
                    online={state.online}
                    group={state.group}
                    alias={state.alias}
                />

                {handleCSV()}
            </Paper>

            <Paper className={'paper'}>
                {!state.loading ? handleDisplay() : <Loader/>}
            </Paper>

            <PopUp
                active={state.activePopUp}
                handlePopUp={handlePopUp}
                title={state.popUpTitle}
                message={state.popUpMSG}
            />
        </div>
    )
};

export default StationCharts;