import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
//MATERIAL UI
import querystring from 'querystring';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// CUSTOM COMP
import LogoSVG_Blue from '../../images/bettair_color@2x.png'
import {EuropeanInfo} from './EuropeanInfo'
//IDM CSS
import './Login.css'
/*** REDUX  ****/
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
/*** REDUX ACTIONS ***/
import {getUserInfo} from "../../actions/user";
import {getUserToken} from '../../requests/user';

/** MATERAIL UI**/
const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Index = props => {
    const dispatch = useDispatch();

    const [logged, mustLogIn] = useSelector(state => [state.user.logged, state.user.mustLogIn], shallowEqual);

    const classes = useStyles();
    const [values, setValues] = useState({
        email: '',
        password: '',
        remember: false
    });

    getUserInfo()(dispatch);

    const [warningPassword, setWarningPassword] = useState(false);
    const [warning, setWarning] = useState(false);

    function handleSubmit(e) {
        if (values.email === "") {
            setWarningPassword(true);
            setWarning(false);
        } else if (values.password === "") {
            setWarningPassword(false);
            setWarning(true);
        } else {
            getUserToken(querystring.stringify({
                grant_type: "password",
                username: values.email,
                password: values.password
            }), (response, err) => {
                if (!err) {
                    sessionStorage.setItem('x-auth-token', response.data.access_token);
                    sessionStorage.setItem('refresh_token', response.data.refresh_token);
                    if (values.remember)
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                    getUserInfo()(dispatch);
                } else {
                    setWarning(true);
                }
            })
        }
    }

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };

    const handleRemember = name => event => {
        setValues({...values, [name]: event.target.checked});
    };

    const search = props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const h = params.get('h') ? params.get('h') : '/dashboard';

    if (mustLogIn) {
        document.getElementById('splash-screen').style.display = 'none';
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <img className="loginLogo" alt={'Bettair'} src={LogoSVG_Blue}/>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="off"
                        autoFocus
                        onChange={handleChange('email')}
                        value={values.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="off"
                        onChange={handleChange('password')}
                        value={values.password}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleRemember("remember")}
                                value="remember"
                                color="primary"
                            />
                        }
                        label="Remember me"
                    />

                    {warningPassword &&
                    <h5 className={'warningMessage'}>Please enter your password</h5>}

                    {warning &&
                    <h5 className={'warningMessage'}>Username or password incorrect</h5>}

                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={5}>
                <EuropeanInfo/>
            </Box>
            {logged && (<Redirect to={h}/>)}
        </Container>
    );
};

export default Index;
