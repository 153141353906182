import {organizations, orion} from '../config/axios'
import {handleError} from "./common";

export const getStationAdmin = (token, pag, callback) => {
    orion.get(`entities?type=station&offset=${pag * 20}&options=count`, {
        headers: {"x-auth-token": token}
    }).then(response => {
        callback(response.data, false)
    }).catch(error => {
        handleError(error, callback, (new_token) => getStationAdmin(new_token, pag, callback));
    })
};


export const getStationOrganization = (token, callback) => {
    orion.get(`entities?type=station`, {
        headers: {"x-auth-token": token}
    }).then(response => {
        callback(response.data, false)
    }).catch(error => {
        handleError(error, callback, (new_token) => getStationOrganization(new_token, callback));
    })
};


export const postStation = (token, body, callback) => {
    orion.post('entities', body, {
        headers: {
            "x-auth-token": token,
            "FIWARE-Service": "stations"
        }
    }).then(response => {
        callback(response.data, false)
    }).catch(error => {
        handleError(error, callback, (new_token) => postStation(new_token, body, callback));
    })
};


export const updateStation = (token, id, body, callback) => {
    orion.patch(`entities/${id}/attrs?type=station`, body, {
        headers: {
            "x-auth-token": token,
            "FIWARE-Service": "stations"
        }
    }).then(response => {
        callback(response.data, false);
    }).catch(error => {
        handleError(error, callback, (new_token) => updateStation(new_token, id, body, callback));
    })
};

/**
 *
 * @param token {string}
 * @param page {number}
 * @param callback {function({status: number, error: string} || {},boolean)}
 */
export const getAllStations = (token, page, callback) => {
    organizations.get(`/station/pag/${page}`, {
        headers: {
            'x-auth-token': token
        }
    }).then(response => {
        callback(response.data, false);
    }).catch(error => {
        handleError(error, callback, (token_n) => getAllStations(token_n, page, callback));
    })
};

export const getLatLonStation = (token, station, callback) => {
    organizations.get(`/station/${station}`, {
        headers: {
            'x-auth-token': token
        }
    }).then(response => {
        callback(response.data, false);
    }).catch(error => {
        handleError(error, callback, (new_token) => getLatLonStation(new_token, station, callback));
    });
};
