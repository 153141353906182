import {
    LOADING,
    FETCH_DATA_FROM_NODES,
    NO_DATA,
    SET_DATE,
    REMOVE_ERRORS,
    CHANGE_MIN_MAX
} from "../../actions/nodeCharts";

let to = new Date();
let from = new Date(to.getTime() - 1000 * 60 * 60 * 24);

const processedFields = () => {
    let aux = {};
    aux = {
        ...aux,
        'pm1': [],
        'pm2': [],
        'pm10': [],
        'time': [],
        'temperature': [],
        'relative_humidity': [],
        'pressure': [],
        'o3': [],
        'no': [],
        'no2': [],
        'co': [],
        'so2': [],
        'h2': []
    };

    return aux;
};


const initialState = {
    loading: true,
    batch: processedFields(),
    nodesId: [],
    noData: false,
    errors: [],
    from,
    to,
    min: from.getTime(),
    max: to.getTime(),
};


const app = (state = initialState, action) => {
        switch (action.type) {
            case LOADING:
                return {
                    ...state,
                    loading: action.isLoading
                };

            case FETCH_DATA_FROM_NODES:
                let newBatch = processedFields();
                Object.keys(action.data).map(station => {

                    for (let measure in action.data[station]) {
                        if (newBatch[measure] && measure !== 'time')
                            newBatch[measure].push({
                                name: `${station}  ${measure}`,
                                type: 'spline',
                                data: action.data[station][measure].map((value, index) => {
                                    return [new Date(action.data[station].time[index]).getTime(), value]
                                })
                            });
                        if (measure === 'time')
                            newBatch[measure].push({name: station, data: action.data[station][measure]})
                    }
                });


                Object.keys(newBatch).forEach(field => {
                    if (newBatch[field][0].data.length === 0)
                        delete newBatch[field]
                });


                return {
                    ...state,
                    nodesId: action.nodeID,
                    batch: newBatch,
                    noData: false,
                    errors: action.errors,

                };

            case CHANGE_MIN_MAX:
                return {
                    ...state,
                    min: action.min,
                    max: action.max,
                };

            case REMOVE_ERRORS:
                if (typeof action.key === 'boolean')
                    return {
                        ...state,
                        errors: []
                    };

                else
                    return {
                        ...state,
                        errors: [
                            ...state.errors.slice(0, action.key),
                            ...state.errors.slice(action.key + 1)]
                    };

            case NO_DATA:
                return {
                    ...state,
                    noData: action.noData,
                    nodesId: action.nodesId,
                    batch: processedFields()
                };

            case SET_DATE:
                return {
                    ...state,
                    [action.name]: action.value,
                };

            default:
                return state
        }
    }
;

export default app;

