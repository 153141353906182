import React, {Component} from 'react';
import Websocket from 'react-websocket';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';

import * as WebSocketActions from '../actions/webSocket';

class webSocket extends Component {
    constructor() {
        super();

        this.state = {
            reconnect: true,
            max_retries: 5 - 2
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.batch.length > 0) {
            this.props.webSocketActions.CleanMessages();
        }
    }

    handleData(msg) {
        let data = JSON.parse(msg);

        if (data.type === 'Close') {
            if (data.code === 401) {
                let newState = this.state;
                newState.reconnect = false;
                this.setState(newState);
            }
        } else if (data.type === 'subscription') {
            this.props.webSocketActions.manageSubscription(data);
        }
    }

    handleOpen() {
        console.log('Web Socket Opened');
        let msg = {
            type: 'token',
            token: sessionStorage.getItem('x-auth-token')
        };
        this.refWebSocket.sendMessage(JSON.stringify(msg));

        let newState = this.state;
        newState.reconnect = true;
        newState.max_retries = 5;

        this.setState(newState);
    }

    handleClose() {
        console.log('Web Socket Closed');
        if (this.state.reconnect) {
            let newState = this.state;

            if (newState.max_retries > 0) {
                newState.max_retries--;
            } else {
                newState.reconnect = false;
            }
        }
    }


    render() {
        if (this.props.batch.length > 0)
            this.refWebSocket.sendMessage(JSON.stringify(this.props.batch[0]));

        return (
            <Websocket
                // url={'ws://localhost:10000/connection/'}
                //url={`wss://${window.location.host}/front_websocket/connection`}
                url={`wss://panel.bettair.city/front_websocket/connection`}
                onMessage={this.handleData.bind(this)}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                reconnect={this.state.reconnect}
                ref={WebSocket => {
                    this.refWebSocket = WebSocket
                }}
            />
        )
    }

}


const DispatchToProps = (dispatch) => {
    return {
        webSocketActions: bindActionCreators(WebSocketActions, dispatch)
    }
};

const StateToProps = (state) => {
    return {
        batch: state.WebSocket.msg
    }
};

export default connect(StateToProps, DispatchToProps)(webSocket)