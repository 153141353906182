import React from 'react'
import './OrganizationForm.css'
/** OWN COMPONENTS */
/** IMPORT REACT TOOLBOX COMPONENTS */
// import Autocomplete from 'react-toolbox/lib/autocomplete/Autocomplete';

import Icon from "@material-ui/core/Icon";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
/*** REDUX  ****/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as OrganizationActions from "../../../../actions/organizations";
import Title from "../../../../assets/bettair/Title";

class StationListForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newStation: false,
            stations: this.props.stations,
            addIconStation: "add_to_home_screen",
            Dialog: {
                active: false,
                alias: '',
                station: '',
                oldAlias: '',
            },
        };

        this.handleDialog = this.handleDialog.bind(this);
        this.handlePopUp = this.handlePopUp.bind(this);
    }

    handlePopUp() {
        this.setState({...this.state, activePopUp: false});
    }

    handleAddStation() {
        if (this.state.newStation) {
            this.setState({...this.state, newStation: false, addIconStation: "add_to_home_screen"});
        } else {
            this.setState({...this.state, newStation: true, addIconStation: "close"});
        }
    }

    handleChange(name, event) {
        const value = event.currentTarget.value;

        if (name === 'alias') {
            let newState = this.state.Dialog;
            newState.alias = value;
            this.setState({...this.state, Dialog: newState});
        } else {
            if (this.props.noStations.map(int => int.id).includes(value)) {
                let station = {
                    station_id: value,
                    organizations: [{
                        shortName: this.props.shortName,
                        alias: "New_Station"
                    }]
                };
                this.props.organizationActions.postStationOrg(station, (response, err) => {
                    if (err) {
                        console.log("ERROR -> " + response);
                        this.errorPopUp("Station has not been added");
                    }
                    this.handleAddStation();
                });
            }
        }
    }

    errorPopUp(msg) {
        this.props.errorPopUp(msg);
    }

    successPopUp(msg) {
        this.props.successPopUp(msg);
    }

    handleDeleteStation(station) {
        let newState = this.state;
        this.props.organizationActions.delStationOrg(this.props.shortName, station, (response, err) => {
            if (err) {
                console.log("ERROR -> " + response.toString());
                this.errorPopUp("Station has not been deleted");
            } else {
                newState.stations.forEach((item, i) => {
                    if (item.station === station) {
                        newState.stations.splice(i, 1);
                    }
                });
                newState.noStations.push(station);
                this.setState(newState);
            }
        });
    }

    handleAlias(station) {
        let newState = this.state.Dialog;

        newState.alias = station.alias;
        newState.oldAlias = station.alias;
        newState.station = station.station;
        newState.active = true;

        this.setState({...this.state, Dialog: newState});
    }

    handleStations() {
        const sortedStations = this.state.stations.sort(function (a, b) {
            return a.alias.localeCompare(b.alias);
        });
        return sortedStations.map((station, key) => {
            return (
                <ListItem
                    button
                    key={`user_${key}`}
                    onClick={this.props.isAdmin ? this.handleAlias.bind(this, station) : null}>
                    <ListItemText
                        primary={`${station.alias}  (${station.station})`}
                    />
                    {this.props.global_admin &&
                    <ListItemSecondaryAction>
                        <IconButton
                            key={`del_station_${key}`}
                            edge="end"
                            aria-label="Delete"
                            onClick={this.handleDeleteStation.bind(this, station.station)}>
                            <Icon>
                                {this.props.isAdmin ? "delete" : null}
                            </Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                    }
                </ListItem>
            );
        });
    }

    handleDialog(name, event) {
        let newState = this.state.Dialog;
        newState.active = false;

        if (name === 'update') {
            if (this.state.Dialog.oldAlias !== this.state.Dialog.alias) {
                this.props.organizationActions.putStationOrg(this.props.shortName, {
                    station_id: this.state.Dialog.station,
                    alias: this.state.Dialog.alias
                }, (response, err) => {
                    if (err) {
                        console.log("ERROR -> " + response.toString());
                        this.errorPopUp("Station has not been updated");
                    } else {

                        const stations = response.data.stations.map((station, index) => {
                            return {
                                station: station.station_id,
                                alias: station.alias
                            };
                        });

                        this.props.successPopUp('Station alias has been updated');

                        this.setState({
                            ...this.state,
                            stations: stations,
                            Dialog: newState
                        });

                    }
                });
                return;
            }
        }
        this.setState({...this.setState, Dialog: newState});
    }

    render() {
        return (
            <div>
                <Title>Stations</Title>
                <List>
                    {this.handleStations()}
                </List>
                {(this.props.noStations.length > 0 && this.props.global_admin) && <IconButton
                    className="buttonClose"
                    icon={this.state.addIconStation}
                    onClick={this.handleAddStation.bind(this)}
                />}
                {/*{this.state.newStation ?*/}
                {/*    <Autocomplete className='inputForm' label="New Station"*/}
                {/*                  direction={"down"}*/}
                {/*                  value={''}*/}
                {/*                  source={this.props.noStations.map(ent => {*/}
                {/*                      return ent.id*/}
                {/*                  })}*/}
                {/*                  multiple={false}*/}
                {/*                  disabled={false}*/}
                {/*                  showSelectedWhenNotInSource={true}*/}
                {/*                  onChange={this.handleChange.bind(this, 'newStationValue')}*/}
                {/*    /> : ""}*/}

                <Dialog open={this.state.Dialog.active}
                        fullWidth={true}
                        maxWidth={'sm'}
                        onClose={this.handleDialog.bind(this, 'cancel')}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle
                        id="form-dialog-title">{`Alias of station "${this.state.Dialog.station}"`}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            id="name"
                            label="Station Alias"
                            type="text"
                            fullWidth
                            required
                            value={this.state.Dialog.alias}
                            onChange={this.handleChange.bind(this, 'alias')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialog.bind(this, 'cancel')} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialog.bind(this, 'update')} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        organizationProp: state.organizations.currentOrganizationDetails,
        global_admin: state.user.admin
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        organizationActions: bindActionCreators(OrganizationActions, dispatch)
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(StationListForm)
