import React, {Component} from 'react'
/** OWN COMPONENTS **/
import OrganizationList from './OrganizationList/OrganizationList'
import OrganizationForm from './OrganizationForm/OrganizationForm'
import OrganizationUsersList from './OrganizationUsersList/OrganizationUsersList'
import AddUserForm from './CreateUser/createUserForm'
/*** REDUX  ****/
import * as OrganizationsActions from '../../../actions/organizations'
import * as UserActions from '../../../actions/user'
import * as ActionStation from '../../../actions/stations'

import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
/** REACT TOOLBOX ***/
import {Route, Switch, withRouter} from "react-router-dom";
import {PropsRoute} from "../../Route";
import NotFound from "../../Route/error404";


class AdminOrganizations extends Component {
    constructor() {
        super();

        this.state = {
            Dialog: {
                active: false,
                delOrg: false,
                item: null,
            }
        };

        this.handleDeleteOrg = this.handleDeleteOrg.bind(this);
        this.handleEditOrg = this.handleEditOrg.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);
        this.handleDialog = this.handleDialog.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        if (this.props.admin) {
            this.props.organizationsActions.listOrganizations();
            this.props.organizationsActions.listUsers();
        }
        // this.props.StationActions.getAllStations();
    }

    handleDeleteOrg(org) {
        // console.log(org);
        let newState = this.state.Dialog;
        newState.delOrg = true;
        // newState.active = true;
        newState.item = org;
        this.setState({...this.state, Dialog: newState});
        this.handleDelete();
    }

    handleEditOrg(org) {
        this.props.organizationsActions.OrganizationDetails(org);
    }

    handleDeleteUser(user) {
        let newState = this.state.Dialog;
        newState.delOrg = false;
        // newState.active = true;
        newState.item = user;
        this.setState({...this.state, Dialog: newState});
        this.handleDelete();
    }

    handleEditUser(user) {
        this.props.userActions.editUser(user);
    }

    handleDelete() {
        if (this.state.Dialog.delOrg) {
            this.props.organizationsActions.deleteOrg(this.state.Dialog.item, (response, err) => {
                if (err)
                    console.log(response);
            });
        } else {
            this.props.userActions.deleteUser(this.state.Dialog.item, (response, err) => {
                if (err)
                    console.log(response);
                else
                    this.props.organizationsActions.listUsers();
            });
        }
        this.handleDialog();
    }

    handleDialog() {
        let newState = this.state.Dialog;
        newState.delOrg = false;
        newState.active = false;
        newState.item = '';
        this.setState({...this.state, Dialog: newState});
    }

    render() {
        return (
            <Switch>
                <PropsRoute exact path={"/organization"} component={OrganizationForm}/>
                <PropsRoute exact path={"/organization/user/new/:id"} component={AddUserForm}/>
                <PropsRoute exact path={"/admin/organizations/detail/:id"} component={OrganizationForm}/>
                <PropsRoute exact path={"/admin/organizations/users/:id"} component={AddUserForm}/>
                <Route exact path="/admin/organizations" render={(props) => {
                    return (
                        <div className="row">
                            <OrganizationList {...props}
                                              handleDeleteOrg={this.handleDeleteOrg}
                            />
                            <OrganizationUsersList {...props}
                                                   handleDeleteUser={this.handleDeleteUser}
                            />
                        </div>
                    )
                }}/>
                <Route path='*' component={NotFound}/>
            </Switch>
        )
    }

}

const dashboardDispatchToProps = (dispatch) => {
    return {
        organizationsActions: bindActionCreators(OrganizationsActions, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
        StationActions: bindActionCreators(ActionStation, dispatch),
    }
};

const dashboardStateToProps = (state) => {
    return {
        admin: state.user.admin,
    }
};


export default withRouter(connect(dashboardStateToProps, dashboardDispatchToProps)(AdminOrganizations))
