import React, {Component} from 'react'
import './UserInfo.css'

/** OWN COMPT ***/
import Account from './Account/Account'
import Organizations from './Organizations/Organization'
/**
 * REDUX
 */
import {connect} from 'react-redux'

class UserInfo extends Component {

    render() {
        return (
            <div className={'row variableHeight'}>
                <div className={'row userInfoContainer'}>
                    <Account/>
                    <Organizations isXs={false}/>
                </div>
                <div className={'row'}>
                    <Organizations isXs={true}/>
                </div>
            </div>
        )
    }
}

const stateToProps = (state) => {
    return {
        isAdmin: (state.user.role === 'admin'),
    }
};

// const dispatchToProps = (dispatch) => {
//     return {
//         dashboardActions: bindActionCreators(dashboardActions, dispatch),
//     }
// };

export default connect(stateToProps, null)(UserInfo)
