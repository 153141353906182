import React from 'react'
import {Redirect, Route} from 'react-router-dom'
/*** REDUX  ****/
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
/*** REDUX ACTIONS ***/
import * as UserActions from '../../actions/user';

import WebSocket from "../WebSocket";


class AuthorizedRoute extends React.Component {
    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <div>
                {this.props.logged && <WebSocket/>}
                <Route {...rest} render={props => {
                    return this.props.logged ? (this.props.admin ? (this.props.isAdmin ?
                        <Component {...props} />
                        : <Redirect to="/"/>)
                        : <Component {...props} />)
                        : <Redirect to={`/login?h=${this.props.location.pathname}`}/>
                }}/>
            </div>
        )
    }
}

const dashboardStateToProps = (state) => {
    return {
        logged: state.user.logged,
        isAdmin: state.user.admin
    }
};

const dashboardDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(UserActions, dispatch),
    }
};

export default connect(dashboardStateToProps, dashboardDispatchToProps)(AuthorizedRoute)
