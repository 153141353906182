import {
    GET_ORGANIZATIONS,
    SET_CURRENT_ORGANIZATION,
    GET_USERS,
    SET_TEMP_ORGANIZATION,
    CHANGE_CHECKED_STATION_MANAGE
} from '../../actions/organizations'

const initialState = {
    organizationsList: [],
    userslist: []
};

const organizations = (state = initialState, action) => {
    let aux;
    switch (action.type) {
        case CHANGE_CHECKED_STATION_MANAGE:

            return {
                ...state,
                currentOrganizationDetails: {
                    ...state.currentOrganizationDetails,
                    stations: state.currentOrganizationDetails.stations.map((station, key) => {
                        if (key === action.index_old)
                            station.checked = !station.checked;
                        return station;
                    })
                }
            };

        case GET_ORGANIZATIONS:
            return {
                ...state,
                organizationsList: action.data,
            };
        case SET_CURRENT_ORGANIZATION:
            action.organization.stations.forEach(org => {
                org.checked = false;
                return org;
            });
            return {
                ...state,
                currentOrganizationDetails: action.organization
            };
        case GET_USERS:
            return {
                ...state,
                userslist: action.data,
            };
        case SET_TEMP_ORGANIZATION:
            aux = action.organization;

            aux.stations = action.organization.stations.map(org => {
                org.checked = false;
                return org;
            });
            return {
                ...state,
                currentOrganizationDetails: aux
            };
        default:
            return state

    }

};

export default organizations
