import {GET_ALL_SENSOR} from "../../actions/devices";
import {SUBSCRIPTION_DEVICE} from "../../actions/webSocket";

const initialState = {
    list: [],
    count: 0
};

const devices = (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_SENSOR:
            return {
                ...state,
                list: action.sensorList,
                count: action.sensorList.length
            };
        case SUBSCRIPTION_DEVICE:
            return {
                ...state,
                list: action.sensors,
                count: action.sensors.length
            };
        default:
            return state
    }
};

export default devices
