import * as orgRequest from '../requests/organizations'
import {getUserInfo} from "./user";

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const GET_USERS = 'GET_USERS';
export const SET_TEMP_ORGANIZATION = 'SET_TEMP_ORGANIZATION';
export const CHANGE_CHECKED_STATION_MANAGE = "CHANGE_CHECKED_STATION_MANAGE";


export const listOrganizations = () => {
    return (dispatch, getState) => {
        let admin = getState().user.admin;

        if (admin)
            orgRequest.getOrganizations(sessionStorage.getItem('x-auth-token'), (data, err) => {
                if (!err) {
                    dispatch(getUserInfo());
                    /*
                    dispatch({
                        type: GET_ORGANIZATIONS,
                        data
                    });*/
                }
            });
        else {
            dispatch(getUserInfo());
        }
    }
};

export const getCurrentOrganizationDetails = (shortName) => {
    return (dispatch) => {
        orgRequest.getOrganizationDetails(localStorage.getItem('x-auth-token'), shortName, (response, err) => {
            if (!err) {
                dispatch({
                    type: SET_CURRENT_ORGANIZATION,
                    organization: response
                });
            }/* else if (response.status === 401 && err) {
                null;
                //dispatch(setLoggedOutUser());TODO
            }*/
        });
    };
};

export const OrganizationDetails = (shortName) => {
    return (dispatch) => {
        orgRequest.getOrganizationDetails(localStorage.getItem('x-auth-token'), shortName, (response, err) => {
            if (!err) {
                dispatch({
                    type: SET_TEMP_ORGANIZATION,
                    organization: response
                });
                //dispatch(changeDashboardContent({content: 'admin_organization', view: 'org_form_edit'}))
            }/* else if (response.status === 401 && err) {
                null;
                //dispatch(setLoggedOutUser());TODO
            }*/
        });
    }
};

export const clearRegister = () => {
    return {
        type: CLEAR_FIELDS
    }
};

export const listUsers = () => {
    return (dispatch) => {
        orgRequest.getUsers(sessionStorage.getItem('x-auth-token'), (data, err) => {
            if (!err) {
                dispatch({
                    type: GET_USERS,
                    data
                });
            }
        });
    }
};

export const postOrg = (newOrg, callback) => {
    return (dispatch) => {
        orgRequest.postOrganization(sessionStorage.getItem('x-auth-token'), newOrg, (response, error) => {
            console.log(response, error);
            if (error)
                console.log(response);
            else {
                dispatch(listOrganizations());
                callback(response, error);
            }
        });
    }
};

export const putOrg = (org, entity, callback) => {
    return (dispatch) => {
        orgRequest.putOrganization(sessionStorage.getItem('x-auth-token'), org, entity, (response, error) => {
            if (error)
                console.log(response);
            else {
                callback(response, error);
                dispatch(listOrganizations());
            }
        });
    }
};

export const deleteOrg = (org, callback) => {
    return (dispatch) => {
        orgRequest.deleteOrganization(sessionStorage.getItem('x-auth-token'), org, (response, error) => {
            if (error)
                console.log(response);
            else {
                dispatch(listOrganizations());
                callback(response, error);
            }
        });
    }
};

export const postUserOrg = (org, user, callback) => {
    return (dispatch) => {
        let entity = {
            org: org,
            user: user.name,
            body: {
                isAdmin: user.isAdmin
            }
        };
        orgRequest.postUserIntoOrganization(sessionStorage.getItem('x-auth-token'), entity, (response, error) => {
            if (error) {
                console.log(response);
            } else {
                callback(response, error);
                dispatch(listOrganizations());
            }
        });
    }
};

export const delUserOrg = (org, user, callback) => {
    return (dispatch) => {
        orgRequest.deleteUserFromOrganization(sessionStorage.getItem('x-auth-token'), {
            org: org,
            user: user.user_id,
            admin: user.isAdmin
        }, (response, error) => {
            if (error) {
                console.log(response);
            } else {
                callback(response, error);
                dispatch(listOrganizations());
            }
        });
    }
};

export const delStationUser = (org, user, station, callback) => {
        return (dispatch) => {
            let entity = {
                org: org,
                station: station,
                user: user
            };
            orgRequest.deleteStationFromUser(sessionStorage.getItem('x-auth-token'), entity, (response, error) => {
                if (error)
                    callback(response, true);
                else {
                    callback(response, false);
                    dispatch(listOrganizations());
                }
            });
        }
    }
;

export const addStationUser = (org, user, station, callback) => {
    return (dispatch) => {
        let entity = {
            org: org,
            station: station,
            user: user
        };
        orgRequest.addStationToUser(sessionStorage.getItem('x-auth-token'), entity, (response, error) => {
            if (!error) {
                callback(response, false);
                dispatch(listOrganizations());
            } else callback(response, true);
        });
    }
};

export const modifyCheckedState = (index) => {
    return {
        type: CHANGE_CHECKED_STATION_MANAGE,
        index
    }
};

export const postStationOrg = (station, callback) => {
    return dispatch => {
        orgRequest.postStationOrganization(sessionStorage.getItem('x-auth-token'), station, (response, error) => {
            if (error)
                console.log(response);
            else {
                callback(response, error);
                dispatch(listOrganizations());
            }
        });
    }
};

export const delStationOrg = (org, station, callback) => {
    return dispatch => {
        orgRequest.deleteStationOrganization(sessionStorage.getItem('x-auth-token'), station, org, (response, error) => {
            if (error) console.log(response);
            else {
                dispatch(listOrganizations());
                callback(response, error);
            }
        });
    }
};

export const putStationOrg = (org, station, callback) => {
    let entity = {
        org: org,
        station: station.station_id,
        alias: station.alias
    };
    return dispatch => {
        orgRequest.putStationOrganization(sessionStorage.getItem('x-auth-token'), entity, (response, error) => {
            if (error)
                console.log(response);
            else {
                dispatch(listOrganizations());
                callback(response, error);
            }
        });
    }
};
