import {getUserToken} from "./user";
import querystring from "querystring";

export const handleError = (error, callback, callback_refresh) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status !== 401 || (!sessionStorage.getItem('refresh_token') && !localStorage.getItem('refresh_token')))
            callback({status: error.response.status, error: error.response.data}, true);
        else {
            getUserToken(querystring.stringify({
                grant_type: 'refresh_token',
                refresh_token: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : sessionStorage.getItem('refresh_token')
            }), (response, err) => {
                if (!err) {
                    sessionStorage.setItem('x-auth-token', response.data.access_token);
                    sessionStorage.setItem('refresh_token', response.data.refresh_token);
                    if (localStorage.getItem('refresh_token')) {
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                    }
                    callback_refresh(response.data.access_token);
                }
            })
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an mapGenerator of XMLHttpRequest in the browser and an mapGenerator of
        // http.ClientRequest in node.js
        callback({status: 400, error: 'Service could not be reached'}, true);
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        callback({status: 400, error: 'Service could not be reached'}, true);
    }
};