import { ADD_GENERAL_INFO,
    ADD_GAS_INFO,
    CHANGE_GAS_FIELD,
    CHANGE_PM_FIELD,
    CLEAR_FIELDS,
    GET_DEVICE_INFO
} from '../../actions/nodeDetails'


const gasFields =['SN*_TYPE',
    'SN*_SN',
    'SN*_WE_ELECTRONIC_ZERO',
    'SN*_WE_SENSOR_ZERO',
    'SN*_AE_ELECTRONIC_ZERO',
    'SN*_AE_SENSOR_ZERO',
    'SN*_A',
    'SN*_B',
    'SN*_C',
    'SN*_D',
    'SN*_SENSITIVITY',
    'SN*_KIT',
    'SN*_GAS_OFFSET',
    'SN*_GAS_GAIN'
];

export const SN_TYPES = ['NO2A43F','OXA431','NOA4','COA4','SO2A4','H2SA4','NONE'];
export const createGasesInfo = ()=>{
    const gasesInfo =[];

    for(let i=1 ; i<=4; i++){
        let gasInfo={};

        gasFields.forEach(field =>{
            let fieldName= field.replace('*', i );

            if (fieldName === `SN${i}_TYPE`){
                gasInfo[fieldName] = SN_TYPES[i-1];
            }else{
                gasInfo[fieldName]='';
            }
        });
        gasesInfo.push(gasInfo);

    }
    return gasesInfo;
};
const createInitialState = ()=>{
    const gasesInfo = createGasesInfo();
    return {
        generalInfo: {'SENSOR_ID':'',
            'BT_MAC':'',
            'CIRCUIT_TYPE':'',
            'CIRCUIT_SERIAL':'',
            'LOCATION_CURRENT':'',
            'CALIBRATION_UNIT':'',
            'CALIBRATION_STATION':'',
            'CALIBRATION_STATION_EOI_CODE':'',
            'CALIBRATION_START_DATE': new Date(),
            'CALIBRATION_END_DATE': new Date(),
            'CLIENT':'',
            'STATUS':'',
            'COMMENTS':'',
            'ACCESSORY':'',
            'UPDATED':'',
            'INSTITUTION_ID':'',
            'ACTIVE':false
        },
        gasesInfo: gasesInfo,
        PM: {'PM':'',
            'PM_SN':''
        },
        requiredFields:['SENSOR_ID',
            'BT_MAC',
            'CIRCUIT_TYPE',
            'CIRCUIT_SERIAL',
            'LOCATION_CURRENT',
            'CALIBRATION_UNIT',
            'CALIBRATION_STATION',
            'CALIBRATION_STATION_EOI_CODE'
        ],
        SN_TYPES
    };
};


const initialState = createInitialState();

const registerStore = (state = initialState, action) => {
    switch (action.type) {
        case ADD_GENERAL_INFO:

            return {
                ...state,
                generalInfo: action.generalInfo,
            };

        case ADD_GAS_INFO:
            let gasesInfo = state.gasesInfo;

            gasesInfo[action.gasNumber]= action.gasInfo;
            return {
                ...state,
                gasesInfo,
            };

        case CHANGE_GAS_FIELD:
            let gasInfo = state.gasesInfo[action.gasNumber];
            gasInfo[action.field] = action.value;

            state.gasesInfo[action.gasNumber]=gasInfo;
            return {
                ...state
            };

        case CHANGE_PM_FIELD:
            let PM = state.PM;
            PM[action.field] = action.value;

            return {
                ...state,
                PM,
            };

        case CLEAR_FIELDS:
            return createInitialState();

        case GET_DEVICE_INFO:
            action.generalInfo['CALIBRATION_START_DATE'] = new Date(action.generalInfo['CALIBRATION_START_DATE']);
            action.generalInfo['CALIBRATION_END_DATE'] =  new Date(action.generalInfo['CALIBRATION_END_DATE']);
            action.generalInfo['ACTIVE'] =  action.generalInfo['ACTIVE'] === 'true';

            return {
                ...state,
                gasesInfo: action.gasesInfo,
                generalInfo: action.generalInfo,
                PM: action.PM
            };

        default:
            return state
    }
};

export default registerStore
