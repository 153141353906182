import React from 'react'
import EuropeanFlag from '../../images/flag_yellow_low.jpg'

export const EuropeanInfo = props => {
    return (
        <div className='row europeanInfo'>
            <div className='europeanFlag'>
                <img src={EuropeanFlag} width={'80px'} alt='EuropeanFlag'/>
            </div>
            <div className='specs-description'>
                <p>
                    This project has received funding from the European
                </p>
                <p>
                    Union’s Horizon 2020 research and innovation
                </p>
                <p>
                    programme under sub-grant agreement No 732947-009
                </p>
            </div>
        </div>
    )
};