import {getDeviceList, UpdateDevice, CreateDevice} from "../requests";

export const GET_ALL_SENSOR = 'GET_ALL_SENSOR';

export const getAllDevices = () => {
    return dispatch => {
        let sensorList = [];
        let recursive = (ind) => {
            getDeviceList(sessionStorage.getItem('x-auth-token'), ind, (resp, err) => {
                if (!err && resp.length > 0) {
                    sensorList = sensorList.concat(resp);
                    recursive(ind + 1);
                } else {
                    dispatch({
                        type: GET_ALL_SENSOR,
                        sensorList
                    })
                }
            })
        };
        recursive(0);
    }
};


export const updateDevice = (device, callback) => {
    return dispatch => {

        let id = device.id;
        let body = {
            generalInfo: device.generalInfo,
            LAT: device.LAT,
            LON: device.LON
        };

        UpdateDevice(sessionStorage.getItem('x-auth-token'), id, body, (resp, err) => {
            if (err) {
                console.log(resp);
            } else {
                // dispatch(getAllDevices());
                callback(null, false);
            }
        })
    }
};


export const createDevice = (device, callback) => {
    return dispatch => {

        let params = device.generalInfo.value;

        let body = {
            type: "info",
            id: device.id,
            lastActivity: {},
            "generalInfo": {
                "value": {
                    "SENSOR_ID": params.SENSOR_ID,
                    "STATUS": params.STATUS,
                    "POSITION": params.POSITION,
                    "H_V": params.H_V,
                    "F_V": params.F_V,
                    "BT_MAC": "",
                    "CIRCUIT_TYPE": "",
                    "CIRCUIT_SERIAL": "",
                    "LOCATION_CURRENT": "",
                    "CALIBRATION_UNIT": "",
                    "CALIBRATION_STATION": "",
                    "CALIBRATION_STATION_EOI_CODE": "",
                    "CALIBRATION_START_DATE": 23232,
                    "CALIBRATION_END_DATE": 23232
                }
            },
            LAT: {
                value: device.LAT.value
            },
            LON: {
                value: device.LON.value
            }
        };

        CreateDevice(sessionStorage.getItem('x-auth-token'), body, (resp, err) => {
            if (err)
                callback(resp, true);
            else {
                // dispatch(getAllDevices());
                callback(null, false);
            }
        });
    }
};