import {organizations} from '../config/axios'
import {handleError} from "./common";


/**
 *
 * @param user { {
 *   name: string,
 *   password: string,
 *   email: string,
 *   role:string
 * }}
 * @param token {string}
 * @param callback {function({}| null |{status: number, error: string},boolean)}
 */
export const createUser = (user, token, callback) => {

    organizations.post('/user', user, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(null, false);
        })
        .catch(err => {
            handleError(err, callback, (new_token) => createUser(user, new_token, callback));
        });
};


/**
 *
 * @param user { {
 *   name: string,
 *   password: string,
 *   email: string,
 *   role:string
 * }}
 * @param organization {string}
 * @param token {string}
 * @param callback {function({}| null |{status: number, error: string},boolean)}
 */
export const createUserOrganization = (user, organization, token, callback) => {

    organizations.post(`/organization/${organization}/newUser`, user, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(null, false);
        })
        .catch(err => {
            handleError(err, callback, (new_token) => createUser(user, new_token, callback));
        });
};

/**
 *
 * @param user
 * @param id
 * @param token
 * @param callback
 */
export const updateUser = (user, id, token, callback) => {

    organizations.put(`/user/${id}`, user, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(err => {
            handleError(err, callback, (new_token) => updateUser(user, new_token, callback));
        });
};

/**
 *
 * @param token {string}
 * @param callback {function(*, boolean)}
 */
export const getRoles = (token, callback) => {
    organizations.get('/auth/roles', {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response.data, false)
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const getOrganizations = (token, callback) => {
    organizations.get('organization/', {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response.data, false)
        })
        .catch(error => {
            handleError(error, callback, (token_n => getOrganizations(token_n, callback)));
        })
};


/**
 *
 * @param token {string}
 * @param callback {function(object| {status:number, error: string}, boolean)}
 */
export const validateToken = (token, callback) => {
    organizations.get('auth/token', {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback({body: response.data, token: response.headers['x-subject-token']}, false);
        })
        .catch(err => {
            handleError(err, callback);
        });
};


/**
 *  Gets detailed information of a specific organization
 * @param token {string}
 * @param organizationId {string}
 * @param callback {function ( {} | {status: number, error: string} , boolean)}
 */
export const getOrganizationDetails = (token, organizationId, callback) => {
    organizations.get(`organization/${organizationId}`, {
        headers: {'x-auth-token': token}
    }).then(response => {
        callback(response.data, false);
    }).catch(error => {
        handleError(error, callback)
    });
};


/**
 *
 * @param token {string}
 * @param callback {function(object| {status:number, error: string}, boolean)}
 */
export const getUsers = (token, callback) => {
    organizations.get('user/', {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response.data, false)
        })
        .catch(error => {
            handleError(error, callback);
        })
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const postOrganization = (token, entity, callback) => {
    organizations.post('organization/', entity, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            console.log(response);
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => postOrganization(new_token, entity, callback));
        });
};


/**
 *
 * @param token
 * @param organization
 * @param entity
 * @param callback
 */
export const putOrganization = (token, organization, entity, callback) => {
    organizations.put('organization/' + organization, entity, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => putOrganization(new_token, organization, entity, callback));
        });
};

/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const deleteOrganization = (token, entity, callback) => {
    organizations.delete('organization/' + entity, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => deleteOrganization(new_token, entity, callback));
        })
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const postUserIntoOrganization = (token, entity, callback) => {
    organizations.post(`organization/${entity.org}/user/${entity.user}`, entity.body, {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => postUserIntoOrganization(new_token, entity, callback));
        });
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const deleteUserFromOrganization = (token, entity, callback) => {
    organizations.delete(`organization/${entity.org}/user/${entity.user}`, {
        headers: {
            "x-auth-token": token,
            "admin": entity.admin
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => deleteUserFromOrganization(new_token, entity, callback));
        });
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const deleteStationFromUser = (token, entity, callback) => {
    organizations.delete(`organization/${entity.org}/station/${entity.station}/${entity.user}`, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => deleteStationFromUser(new_token, entity, callback));
        });
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const addStationToUser = (token, entity, callback) => {
    organizations.put(`organization/${entity.org}/station/${entity.station}/${entity.user}`, '', {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => addStationToUser(new_token, entity, callback));
        })
};


/**
 *
 * @param token
 * @param station
 * @param callback
 */
export const postStationOrganization = (token, station, callback) => {
    organizations.post('organization/station', station, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => postStationOrganization(new_token, station, callback));
        });
};


/**
 *
 * @param token
 * @param station
 * @param org
 * @param callback
 */
export const deleteStationOrganization = (token, station, org, callback) => {
    let body = {
        organizations: [org]
    };
    organizations.delete(`organization/station/${station}/`, {
        headers: {
            "x-auth-token": token
        },
        data: body
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback, new_token => deleteStationOrganization(new_token, station, org, callback));
        });
};


/**
 *
 * @param token
 * @param entity
 * @param callback
 */
export const putStationOrganization = (token, entity, callback) => {
    let body = {
        newAlias: entity.alias
    };
    organizations.put(`organization/${entity.org}/station/${entity.station}/`, body, {
        headers: {
            "x-auth-token": token
        }
    })
        .then(response => {
            callback(response, false);
        })
        .catch(error => {
            handleError(error, callback);
        });
};
