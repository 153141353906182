import {CLEAN_BATCH, GET_STATIONS_ADMIN, GET_STATIONS_ORGANIZATION} from "../../actions/stations";
import {SUBSCRIPTION_DATA, SUBSCRIPTION_STATIONS} from "../../actions/webSocket";

export const stationStatus = ['active', 'idle', 'disabled'];

const initialState = {
    stations: [],
    count: 0,
    batch: []
};

const stations = (state = initialState, action) => {
    // let aux;
    switch (action.type) {
        case GET_STATIONS_ADMIN:
            return {
                ...state,
                stations: action.stationList,
                count: action.stationList.length
            };

        case GET_STATIONS_ORGANIZATION:
            return {
                ...state,
                stations: action.stationList,
                count: action.stationList.length
            };
        case SUBSCRIPTION_STATIONS:
            return {
                ...state,
                stations: action.stations,
                count: action.stations.length
            };

        case SUBSCRIPTION_DATA:
            return {
                ...state,
                batch: action.data
            };

        case CLEAN_BATCH:
            return {
                ...state,
                batch: action.batch
            };
        default:
            return state

    }
};

export default stations
