import * as async from 'async';

export const SUBSCRIPTION_DEVICE = 'SUBSCRIPTION_DEVICE';
export const SUBSCRIPTION_STATIONS = 'SUBSCRIPTION_STATIONS';
export const SUBSCRIPTION_DATA = 'SUBSCRIPTION_DATA';
export const SEND_DATA = 'SEND_DATA';
export const CLEAN_DATA = 'CLEAN_DATA';

export const manageSubscription = (payload) => {
    return (dispatch, getState) => {
        if(payload.content.type === 'info' && payload.content.service === 'sensor') {
            let sensors = getState().devices.list;

            async.each(payload.data, (item, cb) => {
                let ind = sensors.findIndex(element => element.id === item.id);

                if(ind >= 0) {
                    sensors[ind] = item;
                    cb();
                } else {
                    sensors.push(item);
                    cb();
                }
            }, err => {
                if (err) console.log(err);

                dispatch({
                    type: SUBSCRIPTION_DEVICE,
                    sensors
                })
            });
        }else if(payload.content.type === 'station' && payload.content.service === 'stations') {
            let stations = getState().stations.stations;

            async.each(payload.data, (item, cb) => {
                let ind = stations.findIndex(element => element.id === item.id);

                if(ind >= 0) {
                    stations[ind] = item;
                    cb();
                } else {
                    stations.push(item);
                    cb();
                }
            }, err => {
                if (err) console.log(err);

                dispatch({
                    type: SUBSCRIPTION_STATIONS,
                    stations
                });
            });

        }else if(payload.content.type === 'data' && payload.content.service === 'stations') {
            let data = getState().stations.batch.slice(0);

            data.push(payload.data);

            dispatch({
                type: SUBSCRIPTION_DATA,
                data
            });
        }
    }
};


export const sendDataWebSocket = payload => {
    return (dispatch, getState) => {
        let msg = getState().WebSocket.msg.slice(0);

        msg.push(payload);

        dispatch({
            type: SEND_DATA,
            msg
        });
    }
};


export const CleanMessages = () => {
    return (dispatch, getState) => {
        let msg = getState().WebSocket.msg.slice(1);

        dispatch({
            type: CLEAN_DATA,
            msg
        });
    }
};