import * as stationsAPI from '../requests/stations';

export const GET_STATIONS_ADMIN = 'GET_STATIONS_ADMIN';
export const GET_STATIONS_ORGANIZATION = 'GET_STATIONS_ORGANIZATION';
export const CLEAN_BATCH = 'CLEAN_BATCH';

export const getAllStations = (Admin) => {
    return dispatch => {
        if (Admin) {
            let stationList = [];
            let recursive = (ind) => {
                stationsAPI.getStationAdmin(sessionStorage.getItem('x-auth-token'), ind, (resp, err) => {
                    if (!err && resp.length > 0) {
                        stationList = stationList.concat(resp);
                        recursive(ind + 1);
                        //fiware-total-count
                    } else {
                        dispatch({
                            type: GET_STATIONS_ADMIN,
                            stationList
                        })
                    }
                })
            };
            recursive(0);
        } else {

        }
    }
};

export const getAllStationsOrganization = () => {
    return dispatch => {
        let stationList = [];
        stationsAPI.getStationOrganization(sessionStorage.getItem('x-auth-token'), (resp, err) => {
            if (!err && resp.length > 0) {
                stationList = stationList.concat(resp);
                dispatch({
                    type: GET_STATIONS_ORGANIZATION,
                    stationList
                })
            } else {
                console.log(err);
            }
        })
    }
};


export const updateStation = (station_id, station, callback) => {
    return dispatch => {
        let body = {
            currentNode: {
                value: {
                    node_id: station.currentNode.node_id,
                    from: station.currentNode.from
                }
            },
            status: {
                value: station.status
            }
        };

        stationsAPI.updateStation(sessionStorage.getItem('x-auth-token'), station_id, body, (resp, err) => {
            if (err)
                callback(resp, true);
            else {
                // dispatch(getAllStations(true));
                callback(null, false);
            }
        });

    }
};


export const createStation = (station, id, callback) => {
    return dispatch => {

        let body = {
            type: "station",
            id: id,
            status: {
                "value": station.status
            },
            currentNode: {
                "value": {
                    "node_id": station.currentNode.node_id,
                    "from": new Date().toISOString()
                }
            },
            historyNodes: {value: []},
            lastActivity: {}
        };

        stationsAPI.postStation(sessionStorage.getItem('x-auth-token'), body, (resp, err) => {
            if (err)
                callback(resp, true);
            else {
                // dispatch(getAllStations(true));
                callback(null, false);
            }
        });
    }
};


export const cleanBatch = () => {
    return (dispatch, getState) => {
        let batch = getState().stations.batch.slice(1);

        dispatch({
            type: CLEAN_BATCH,
            batch
        })
    };
};